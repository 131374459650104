import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useMemo } from 'react';

import { SportIcon } from '../../../icons';
import { Tooltip } from '../../../components';
import { Checkbox } from '../../../components/Inputs';
import { StringField, DateField, LinkField } from '../../../components/Fields';
import {
  ExpandableTable,
  FancyTable,
  TableHeaderRow,
} from '../../../components/Table';
import {
  useTableRowsRender,
  useTableColumnToggle,
  useTableColumnsReorder,
  useTableGrid,
  useTableColumns,
} from '../../../hooks';

import { eventSourcesMapping } from '../utils';
import { getGroupAffiliation } from './helpers';
import EventManagementGroupTable from './EventManagementGroupTable';
import { tableNames } from '../../../hooks/useLocalStorageTableConfig';

const useStyles = makeStyles({
  createButton: {
    fontWeight: 600,
    cursor: 'pointer',
  },
  emptyId: {
    background: '#031224',
    height: 1,
    width: 124,
  },
  sourcesTootlip: {
    display: 'inline',
  },
  highlightedSource: {
    textDecoration: 'underline',
  },
  sport: {
    display: 'flex',
    alignItems: 'center',
  },
  sportIcon: {
    marginRight: 4,
    backgroundSize: 16,
    minWidth: 18,
  },
  invertedIcon: {
    backgroundColor: '#8496AB',
    borderRadius: '50%',
    width: '12px',
    height: '12px',
  },
  invertedActiveIcon: {
    backgroundColor: '#08CA6D',
  },
});

export const COLUMNS = {
  ID: 'Event Id',
  SCHEDULED: 'Event Scheduled',
  SPORT: 'Sport',
  AFFILIATION: 'Affiliation',
  OPEN_BETS: 'Open Bets',
  BETS: 'Bets',
  SOURCES: 'Sources',
  STATUS: 'Status',
  MERGE: 'Merge',
  INVERTED: 'Inverted',
};

export const initialToggledColumns = [
  COLUMNS.ID,
  COLUMNS.SCHEDULED,
  COLUMNS.SPORT,
  COLUMNS.AFFILIATION,
  COLUMNS.OPEN_BETS,
  COLUMNS.BETS,
  COLUMNS.SOURCES,
  COLUMNS.INVERTED,
  COLUMNS.STATUS,
  COLUMNS.MERGE,
];

const columnsOrderConfig = [
  COLUMNS.ID,
  COLUMNS.SCHEDULED,
  COLUMNS.SPORT,
  COLUMNS.AFFILIATION,
  COLUMNS.OPEN_BETS,
  COLUMNS.BETS,
  COLUMNS.SOURCES,
  COLUMNS.INVERTED,
  COLUMNS.STATUS,
  COLUMNS.MERGE,
];

const columnsWidthConfig = {
  [COLUMNS.ID]: 1.75,
  [COLUMNS.SCHEDULED]: 1.25,
  [COLUMNS.SPORT]: 0.75,
  [COLUMNS.AFFILIATION]: 4.65,
  [COLUMNS.OPEN_BETS]: 0.25,
  [COLUMNS.BETS]: 0.25,
  [COLUMNS.SOURCES]: 0.75,
  [COLUMNS.INVERTED]: 0.5,
  [COLUMNS.STATUS]: 0.5,
  [COLUMNS.MERGE]: 0.35,
};

const isDifferentSport = (selected, row) => {
  if (Object.keys(selected).length > 0) {
    const key = Object.keys(selected)[0];
    return selected[key].sportId !== row.sport.id;
  } else {
    return false;
  }
};

const EventManagementTable = ({
  toggledColumns,
  loading,
  items,
  selectedGroups,
  onSelectGroup,
  isMergeCheckboxDisabled,
  onGroupCreate,
  refetchGroups,
  onGroupEdit,
  limit,
  highlightedRow = null,
  activeRow = null,
  onToggleColumns,
}) => {
  const classes = useStyles();

  const handleMergeCheckboxChange = useCallback(
    (checked, data) => {
      if (checked) {
        onSelectGroup({
          ...selectedGroups,
          [data.groupId]: {
            groupId: data.groupId,
            sportId: data.sport.id,
            data: data,
          },
        });
      } else {
        const { [data.groupId]: removedGroup, ...rest } = selectedGroups;

        onSelectGroup({
          ...rest,
        });
      }
    },
    [selectedGroups, onSelectGroup]
  );

  const [_, toggleColumn] = useTableColumnToggle(
    initialToggledColumns,
    onToggleColumns,
    null,
    tableNames.EventManagement
  );

  const [columnsOrder, onReorderColumns] =
    useTableColumnsReorder(columnsOrderConfig);

  const { template, changeColumnWidth } = useTableGrid({
    columns: columnsWidthConfig,
    order: columnsOrder,
    toggledColumns,
    rowActions: true,
    expandable: true,
    initialColumnWidth: columnsWidthConfig,
    onChangeColumnsWidth: () => {},
  });

  const { availableColumns, visibleColumns } = useTableColumns({
    columns: [
      {
        label: COLUMNS.ID,
        key: COLUMNS.ID,
        render: data => {
          return (
            <StringField copy={!!data.booked?.desc.id}>
              {data.booked?.desc.id || <div className={classes.emptyId} />}
            </StringField>
          );
        },
        resizable: true,
      },
      {
        label: COLUMNS.SCHEDULED,
        key: COLUMNS.SCHEDULED,
        render: data => {
          return (
            <StringField>
              <DateField date={data.scheduled} />
            </StringField>
          );
        },
        resizable: true,
      },
      {
        label: COLUMNS.SPORT,
        key: COLUMNS.SPORT,
        render: data => {
          return (
            <StringField className={classes.sport}>
              <SportIcon
                name={data.sport?.id}
                className={classes.sportIcon}
                black
              />
              {data.sport?.name}
            </StringField>
          );
        },
        resizable: true,
      },
      {
        label: COLUMNS.AFFILIATION,
        key: COLUMNS.AFFILIATION,
        render: data => {
          const affiliation = getGroupAffiliation(data);

          return data.booked?.desc.id ? (
            <LinkField href={`/events/${data.booked?.desc.id}`}>
              {affiliation}
            </LinkField>
          ) : (
            <StringField>{affiliation}</StringField>
          );
        },
        resizable: true,
      },
      {
        label: COLUMNS.OPEN_BETS,
        key: COLUMNS.OPEN_BETS,
        render: data => {
          const count = data.betsCount?.open || 0;

          return data.booked?.desc?.id ? (
            <LinkField href={`/events/${data.booked?.desc?.id}/bet-history`}>
              {count}
            </LinkField>
          ) : (
            <StringField>{count}</StringField>
          );
        },
        resizable: true,
      },
      {
        label: COLUMNS.BETS,
        key: COLUMNS.BETS,
        render: data => {
          const count = data.betsCount?.all || 0;

          return data.booked?.desc?.id ? (
            <LinkField href={`/events/${data.booked?.desc?.id}/bet-history`}>
              {count}
            </LinkField>
          ) : (
            <StringField>{count}</StringField>
          );
        },
        resizable: true,
      },
      {
        label: COLUMNS.SOURCES,
        key: COLUMNS.SOURCES,
        render: data => {
          const sourcesList = data.groupItems
            ? [...new Set(data.groupItems?.map(item => item?.provider))]
            : [];

          const parsersMap = data.groupItems?.reduce(
            (acc, { provider, parsers }) => {
              if (acc[provider]) {
                acc[provider] = [...new Set([...acc[provider], ...parsers])];
              } else {
                acc[provider] = [...parsers];
              }
              return acc;
            },
            {}
          );

          const mainItem =
            data.groupItems && data.groupItems.find(item => item.mainItem);

          return (
            <StringField>
              {sourcesList.map((source, i) => (
                <>
                  {i !== 0 && ', '}
                  <Tooltip
                    title={parsersMap[source].join(', ')}
                    tooltipClassName={classes.sourcesTootlip}
                  >
                    <span
                      className={clsx({
                        [classes.highlightedSource]:
                          mainItem && mainItem.provider === source,
                      })}
                    >
                      {eventSourcesMapping[source] || source}
                    </span>
                  </Tooltip>
                </>
              ))}
            </StringField>
          );
        },
        resizable: true,
      },
      {
        label: COLUMNS.INVERTED,
        key: COLUMNS.INVERTED,
        render: data => {
          const isInverted = data.groupItems?.some(item => item?.inverted);

          return (
            <Box
              className={clsx(classes.invertedIcon, {
                [classes.invertedActiveIcon]: isInverted,
              })}
            />
          );
        },
        resizable: true,
      },
      {
        label: COLUMNS.STATUS,
        key: COLUMNS.STATUS,
        render: data => {
          let status = 'Manual';

          if (data.status === 'auto') {
            status = 'Auto';
          }

          if (data.status === 'mergeable') {
            status = 'Mergable';
          }

          const isCreatable = data.status === 'creatable';

          if (isCreatable) {
            status = 'Create';
          }

          const handleClick = () => {
            if (isCreatable) {
              onGroupCreate(data);
            }
          };

          return (
            <StringField
              className={clsx({
                [classes.createButton]: isCreatable,
              })}
              onClick={handleClick}
              color={isCreatable && 'primary'}
            >
              {status}
            </StringField>
          );
        },
        resizable: true,
      },
      {
        label: COLUMNS.MERGE,
        isLabelHidden: true,
        key: COLUMNS.MERGE,
        render: data => {
          const isChecked = !!selectedGroups[data.groupId];
          const differentSport = isDifferentSport(selectedGroups, data);
          const isOutright = data.descType !== 'match';

          return (
            <Checkbox
              value={isChecked}
              disabled={
                (isMergeCheckboxDisabled && !isChecked) ||
                differentSport ||
                isOutright
              }
              onChange={checked => {
                handleMergeCheckboxChange(checked, data);
              }}
            />
          );
        },
        resizable: true,
      },
    ],
    order: columnsOrder,
    toggledColumns,
  });
  const rowActions = useMemo(() => {
    return [
      data =>
        !!data.booked?.desc?.id && {
          key: 'edit',
          label: 'Edit',
          onClick: data => {
            onGroupEdit(data, true);
          },
        },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onGroupEdit]);

  const renderRow = useTableRowsRender({
    availableColumns,
    visibleColumns,
    template,
    rowActions: rowActions,
    highlightedRow,
    highlightedRowDataKey: 'groupId',
    activeRow,
    activeRowDataKey: 'groupId',
  });

  const renderExpandedRow = useCallback(
    (rowIndex, rowData) => {
      return (
        <EventManagementGroupTable
          key={rowData.groupId}
          loading={loading}
          data={rowData}
          refetchGroups={refetchGroups}
        />
      );
    },
    [loading, refetchGroups]
  );

  return (
    <ExpandableTable
      id="groupId"
      loading={loading}
      data={loading ? [] : items}
      // scrollable
      renderRow={renderRow}
      renderExpandedRow={renderExpandedRow}
      renderHeaderRow={
        <TableHeaderRow
          sticky
          rowActions={rowActions}
          toggledColumns={toggledColumns}
          template={template}
          onReorder={onReorderColumns}
          availableColumns={availableColumns}
          visibleColumns={visibleColumns}
          toggleColumn={toggleColumn}
          onChangeColumnWidth={changeColumnWidth}
        />
      }
      limit={limit}
    >
      <FancyTable />
    </ExpandableTable>
  );
};

EventManagementTable.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.array,
  selectedGroups: PropTypes.object,
  setSelectedGroups: PropTypes.func,
  refetchGroups: PropTypes.func,
};

export default EventManagementTable;
