import { gql } from '@apollo/client';

const getEventStatsQuery = gql`
  query getEventStats($eventId: EventId!) {
    data: getEventStats(eventId: $eventId) {
      id
      ggr
      avgBet
      maxBet
      players
      bets
      turnover
      turnoverOpen
      lastBetDate
      lastBetDateBet
      firstBetDate
      firstBetDateBet
      maxGgrEur
      minGgrEur
      freebetBets
      freebetPlayers
      freebetTurnover
      freebetGgr
      canceledBets
      turnoverCanceled
    }
  }
`;

export default getEventStatsQuery;
