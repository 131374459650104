import { useApolloClient } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useContext, useRef } from 'react';
import { ConfirmationPromptContext } from '../../../../../providers/ConfirmationPromptProvider';
import { useTreeSelectContext } from '../../../../../providers/TreeSelectProvider';
import { useToasts } from '../../../../providers/ToastsProvider';
import StringField from '../../../Fields/StringField/StringField';
import NotificationAlert, {
  AlertVariant,
} from '../../../Notifications/NotificationAlert';
import ApplyPresetsModalContent from './ApplyPresetsModalContent';

const useStyles = makeStyles({
  presetCaption: {
    fontWeight: '600',
    lineHeight: '20.253px',
    letterSpacing: '0.253px',
    cursor: 'pointer',
  },
});

const ApplyPreset = ({
  templateType,
  selectKey,
  menuOptions,
  idKey,
  sportId,
  getPresetNamesQuery,
  getPresetQuery,
}) => {
  const classes = useStyles();

  const { setPreset } = useTreeSelectContext();
  const { openConfirmation, closeConfirmation } = useContext(
    ConfirmationPromptContext
  );

  const { showToast } = useToasts();

  const client = useApolloClient();

  const presetCategoryRef = useRef(selectKey === 'sport' ? null : []);
  const presetIdRef = useRef('0');

  const disabled = !sportId;

  const onModalSubmit = useCallback(async () => {
    closeConfirmation();
    client
      .query({
        query: getPresetQuery,
        variables: {
          presetId: presetIdRef.current,
        },
      })
      .then(data => {
        setPreset({
          commands: data.data.data,
          selectType: selectKey,
          idKey,
          itemIds:
            selectKey === 'sport' ? [sportId] : presetCategoryRef.current,
        });
      })
      .catch(() => {
        if (presetIdRef.current === '0') {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
              No presets selected
            </NotificationAlert>
          );
        } else {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
              Something went wrong
            </NotificationAlert>
          );
        }
      });
  }, [
    closeConfirmation,
    getPresetQuery,
    presetIdRef,
    setPreset,
    client,
    idKey,
    selectKey,
    sportId,
    showToast,
  ]);

  const onApplyClick = useCallback(() => {
    client
      .query({
        query: getPresetNamesQuery,
        fetchPolicy: 'no-cache',
        variables: {
          sportId,
        },
      })
      .then(data => {
        const optionsForItemsSelect = menuOptions.map(value => {
          return { label: value.name, value: value[idKey] };
        });

        openConfirmation({
          buttonLabel: 'Apply',
          title: `Apply ${templateType} preset`,
          message: (
            <ApplyPresetsModalContent
              selectType={selectKey}
              items={optionsForItemsSelect}
              presetId={presetIdRef}
              selectItem={presetCategoryRef}
              selectedSportId={sportId}
              presetNames={data.data.data}
            />
          ),
          callback: onModalSubmit,
        });
      })
      .catch(() => {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
            Can't fetch presets
          </NotificationAlert>
        );
      });
  }, [
    openConfirmation,
    templateType,
    menuOptions,
    selectKey,
    presetIdRef,
    sportId,
    client,
    getPresetNamesQuery,
    onModalSubmit,
    idKey,
    showToast,
  ]);

  return (
    <StringField
      color={disabled ? 'disabled' : 'primary'}
      className={classes.presetCaption}
      onClick={disabled ? undefined : onApplyClick}
    >
      {`Apply ${templateType} preset`}
    </StringField>
  );
};

export default ApplyPreset;
