import VirtualizedTable from '../../components/Table/VirtualizedTable';
import useTableColumnToggle from '../../hooks/useTableColumnToggle';
import useTableGrid from '../../hooks/useTableGrid';
import useTableColumns from '../../hooks/useTableColumns';
import StringField from '../../components/Fields/StringField/StringField';
import useTableRowsRender from '../../hooks/useTableRowsRender';
import Stack from '@mui/material/Stack/Stack';
import Chip from '../../components/Chip/Chip';
import getRelatedContingencyLabel from './utils/getRelatedContingencyLabel';
import RelatedContingencyRuleType from './constants/RelatedContingencyRuleType';
import BooleanField from '../../components/Fields/BooleanField/BooleanField';
import TableHeaderRow from '../../components/Table/TableHeaderRow';
import RelatedContingencyRuleTypeLabel from './utils/RelatedContingencyRuleTypeLabel';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { convertUnixTimeToUtc } from '../../utils/timeUtils';
import { tableNames } from '../../hooks/useLocalStorageTableConfig';

const commonCellStyle = {
  padding: '0px 8px',
};

export const COLUMN = {
  RELATED_CONTINGENCIES: 'RELATED_CONTINGENCIES',
  COMBO: 'COMBO',
  DATE_CREATED: 'DATE_CREATED',
  CREATOR: 'CREATOR',
  STATE: 'STATE',
};

export const columnsOrderConfig = [
  COLUMN.RELATED_CONTINGENCIES,
  COLUMN.COMBO,
  COLUMN.DATE_CREATED,
  COLUMN.CREATOR,
  COLUMN.STATE,
];

export const initialToggledColumns = [
  COLUMN.RELATED_CONTINGENCIES,
  COLUMN.COMBO,
  COLUMN.DATE_CREATED,
  COLUMN.CREATOR,
  COLUMN.STATE,
];

export const columnsWidth = {
  [COLUMN.RELATED_CONTINGENCIES]: 5,
  [COLUMN.COMBO]: '100px',
  [COLUMN.DATE_CREATED]: '140px',
  [COLUMN.CREATOR]: '150px',
  [COLUMN.STATE]: '120px',
};

const RelatedContingenciesTable = ({
  onToggleColumns,
  toggledColumns: initialToggledColumns,
  order,
  onReorder,
  items,
  loading,
  rowActions,
  tableOffsetTop,
}) => {
  const [toggledColumns, toggleColumn] = useTableColumnToggle(
    initialToggledColumns,
    onToggleColumns,
    null,
    tableNames.RelatedContingencies
  );

  const { template } = useTableGrid({
    columns: columnsWidth,
    order,
    toggledColumns,
    rowActions: true,
  });

  const { availableColumns, visibleColumns } = useTableColumns({
    columns: [
      {
        label: 'Related Contingencies',
        key: COLUMN.RELATED_CONTINGENCIES,
        style: commonCellStyle,
        render: ({ left, right }) => {
          return (
            <Stack
              spacing={0.5}
              direction={'column'}
              alignItems={'flex-start'}
              sx={{
                maxWidth: '100%',
              }}
            >
              <Tooltip title={getRelatedContingencyLabel(left, true)}>
                <Chip sm>{getRelatedContingencyLabel(left)}</Chip>
              </Tooltip>
              <Tooltip title={getRelatedContingencyLabel(right, true)}>
                <Chip sm>{getRelatedContingencyLabel(right)}</Chip>
              </Tooltip>
            </Stack>
          );
        },
      },
      {
        label: 'Combo',
        key: COLUMN.COMBO,
        style: {
          ...commonCellStyle,
          justifyContent: 'center',
          textAlign: 'center',
        },
        render: ({ rule_type }) => {
          return (
            <StringField
              color={
                rule_type === RelatedContingencyRuleType.INCLUDE && 'success'
              }
            >
              {RelatedContingencyRuleTypeLabel[rule_type]}
            </StringField>
          );
        },
      },
      {
        label: 'Date Created',
        key: COLUMN.DATE_CREATED,
        style: commonCellStyle,
        render: ({ update_date }) => {
          return (
            update_date && (
              <StringField number>
                {convertUnixTimeToUtc(update_date)}
              </StringField>
            )
          );
        },
      },
      {
        label: 'Creator',
        key: COLUMN.CREATOR,
        style: commonCellStyle,
        render: ({ user_email }) => {
          return <StringField color={'primary'}>{user_email}</StringField>;
        },
      },
      {
        label: 'State',
        key: COLUMN.STATE,
        style: {
          ...commonCellStyle,
          justifyContent: 'center',
          textAlign: 'center',
        },
        render: ({ active }) => {
          return (
            <Stack direction={'row'} spacing={1}>
              <BooleanField active={active} />

              <StringField>{active ? 'Active' : 'Inactive'}</StringField>
            </Stack>
          );
        },
      },
    ],
    order,
    toggledColumns,
  });

  const renderRow = useTableRowsRender({
    availableColumns,
    visibleColumns,
    template,
    rowActions,
  });

  return (
    <VirtualizedTable
      data={items}
      loading={loading}
      renderRow={renderRow}
      renderHeaderRow={
        <TableHeaderRow
          sticky
          toggledColumns={toggledColumns}
          template={template}
          onReorder={onReorder}
          availableColumns={availableColumns}
          visibleColumns={visibleColumns}
          toggleColumn={toggleColumn}
        />
      }
      rowHeight={64}
      tableOffsetTop={tableOffsetTop}
    />
  );
};

export default RelatedContingenciesTable;
