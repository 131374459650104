import React from 'react';
import { useSearchParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import LinkField from '../../components/Fields/LinkField/LinkField';
import StringField from '../../components/Fields/StringField/StringField';
import DateField from '../../components/Fields/DateField/DateField';
import Switch from '../../components/Inputs/Switch/Switch';
import TableHeaderRow from '../../components/Table/TableHeaderRow';
import FancyTable from '../../components/Table/FancyTable';
import CopyField from '../../components/Fields/CopyField/CopyField';
import Tooltip from '../../components/Tooltip/Tooltip';
import useTableRowsRender from '../../hooks/useTableRowsRender';
import useTableColumns from '../../hooks/useTableColumns';
import useTableGrid from '../../hooks/useTableGrid';
import useTableColumnToggle from '../../hooks/useTableColumnToggle';
import useTableColumnsReorder from '../../hooks/useTableColumnsReorder';
import { EVENT_STATUSES, EVENT_STATUSES_OPTIONS } from './constants';
import { tableNames } from '../../hooks/useLocalStorageTableConfig';

const useStyles = makeStyles({
  status: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  statusCircle: {
    width: 10,
    minWidth: 10,
    height: 10,
    borderRadius: 5,
    marginRight: 6,
  },
  tradeButton: {
    color: '#1581FF',
    cursor: 'pointer',
    fontWeight: 500,
  },
});

const commonCellStyle = {
  padding: '0px 16px',
};
const centeredCellStyle = {
  ...commonCellStyle,
  justifyContent: 'center',
  textAlign: 'center',
};

export const COLUMN = {
  EVENT_ID: 'id',
  SPORT_NAME: 'sport',
  CATEGORY_NAME: 'category',
  TOURNAMENT_NAME: 'tournament',
  SCHEDULED_DATE: 'scheduled',
  COMPETITORS: 'competitors',
  STATUS: 'status',
  MARKETS_COUNT: 'markets',
  LOG_BUTTON: 'log',
  CREATOR_NAME: 'creator',
  TRADE_BUTTON: 'trade_button',
  ACTIVE_TOGGLE: 'active_toggle',
};

export const initialToggledColumns = [
  COLUMN.EVENT_ID,
  COLUMN.SPORT_NAME,
  COLUMN.CATEGORY_NAME,
  COLUMN.TOURNAMENT_NAME,
  COLUMN.SCHEDULED_DATE,
  COLUMN.COMPETITORS,
  COLUMN.STATUS,
  COLUMN.CREATOR_NAME,
  COLUMN.TRADE_BUTTON,
  COLUMN.ACTIVE_TOGGLE,
];

export const columnsOrderConfig = [
  COLUMN.EVENT_ID,
  COLUMN.SPORT_NAME,
  COLUMN.CATEGORY_NAME,
  COLUMN.TOURNAMENT_NAME,
  COLUMN.SCHEDULED_DATE,
  COLUMN.COMPETITORS,
  COLUMN.STATUS,
  COLUMN.CREATOR_NAME,
  COLUMN.TRADE_BUTTON,
  COLUMN.ACTIVE_TOGGLE,
];

export const columnsWidthConfig = {
  [COLUMN.EVENT_ID]: 3.5,
  [COLUMN.SPORT_NAME]: 2,
  [COLUMN.CATEGORY_NAME]: 2,
  [COLUMN.TOURNAMENT_NAME]: 2,
  [COLUMN.SCHEDULED_DATE]: 2.5,
  [COLUMN.COMPETITORS]: 3.5,
  [COLUMN.STATUS]: 2,
  [COLUMN.CREATOR_NAME]: 1.5,
  [COLUMN.TRADE_BUTTON]: 1.5,
  [COLUMN.ACTIVE_TOGGLE]: 1.5,
};

const TradingEventsTable = ({
  loading,
  items,
  onChangeActiveStatus,
  tableOffsetTop,
  orderBy,
  onChangeOrderBy = () => {},
}) => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();

  const [toggledColumns, toggleColumn] = useTableColumnToggle(
    initialToggledColumns,
    null,
    null,
    tableNames.TradingEvents
  );
  const [columnsOrder, onReorderColumns] =
    useTableColumnsReorder(columnsOrderConfig);

  const { template, changeColumnWidth } = useTableGrid({
    columns: columnsWidthConfig,
    order: columnsOrder,
    toggledColumns,
    initialColumnWidth: columnsWidthConfig,
    onChangeColumnsWidth: () => {},
  });

  const { availableColumns, visibleColumns } = useTableColumns({
    columns: [
      {
        label: 'Event ID',
        key: COLUMN.EVENT_ID,
        style: commonCellStyle,
        render: data => {
          return data.platform_id ? (
            <CopyField value={data.platform_id}>
              <LinkField href={`/events/${data.platform_id}`}>
                {data.platform_id}
              </LinkField>
            </CopyField>
          ) : (
            <CopyField value={data[COLUMN.EVENT_ID]}>
              <StringField>{data[COLUMN.EVENT_ID]}</StringField>
            </CopyField>
          );
        },
        sortkey: 'id',
        resizable: true,
      },
      {
        label: 'Sport',
        key: COLUMN.SPORT_NAME,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.SPORT_NAME]}</StringField>;
        },
        resizable: true,
      },
      {
        label: 'Category',
        key: COLUMN.CATEGORY_NAME,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.CATEGORY_NAME]}</StringField>;
        },
        resizable: true,
      },
      {
        label: 'Tournament',
        key: COLUMN.TOURNAMENT_NAME,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.TOURNAMENT_NAME]}</StringField>;
        },
        resizable: true,
      },
      {
        label: 'Date/Time',
        key: COLUMN.SCHEDULED_DATE,
        style: commonCellStyle,
        render: data => {
          return (
            <StringField>
              <DateField date={data[COLUMN.SCHEDULED_DATE]} />
            </StringField>
          );
        },
        sortkey: 'fixture_scheduled',
        resizable: true,
      },
      {
        label: 'Competitors',
        key: COLUMN.COMPETITORS,
        style: commonCellStyle,
        render: data => {
          return (
            <Tooltip overflowOnly title={`${data.competitors.join(' vs ')}`}>
              <StringField>{`${data.competitors.join(' vs ')}`}</StringField>
            </Tooltip>
          );
        },
        resizable: true,
      },
      {
        label: 'Status',
        key: COLUMN.STATUS,
        style: commonCellStyle,
        render: data => {
          const status = data[COLUMN.STATUS];
          const statusOption = EVENT_STATUSES_OPTIONS[status];

          return (
            <StringField className={classes.status}>
              <div
                className={classes.statusCircle}
                style={
                  statusOption.backgroundImage
                    ? {
                        backgroundImage: statusOption.backgroundImage,
                        backgroundSize: 'contain',
                      }
                    : {
                        background: statusOption.color,
                      }
                }
              />
              {statusOption.label}
            </StringField>
          );
        },
        resizable: true,
      },
      {
        label: 'Creator',
        key: COLUMN.CREATOR_NAME,
        style: commonCellStyle,
        render: data => {
          return <StringField>{data[COLUMN.CREATOR_NAME]}</StringField>;
        },
        resizable: true,
      },
      {
        label: 'Trade',
        isLabelHidden: true,
        key: COLUMN.TRADE_BUTTON,
        style: centeredCellStyle,
        render: data => {
          return (
            ![EVENT_STATUSES.canceled, EVENT_STATUSES.finished].includes(
              data[COLUMN.STATUS]
            ) && (
              <LinkField
                href={`/trading-events/${
                  data[COLUMN.EVENT_ID]
                }?${searchParams.toString()}`}
              >
                Trade
              </LinkField>
            )
          );
        },
        resizable: true,
      },
      {
        label: 'Active',
        isLabelHidden: true,
        key: COLUMN.ACTIVE_TOGGLE,
        style: centeredCellStyle,
        render: data => {
          return (
            <Switch
              disabled={[
                EVENT_STATUSES.canceled,
                EVENT_STATUSES.finished,
              ].includes(data[COLUMN.STATUS])}
              value={!data.blocked_markets}
              onChange={value => {
                onChangeActiveStatus({
                  eventId: data[COLUMN.EVENT_ID],
                  activeStatus: value,
                  version: data.version,
                });
              }}
            />
          );
        },
        resizable: true,
      },
    ],
    order: columnsOrder,
    toggledColumns,
  });

  const renderRow = useTableRowsRender({
    availableColumns,
    visibleColumns,
    template,
  });

  return (
    <FancyTable
      loading={loading}
      data={items}
      renderRow={renderRow}
      renderHeaderRow={
        <TableHeaderRow
          toggledColumns={toggledColumns}
          template={template}
          onReorder={onReorderColumns}
          availableColumns={availableColumns}
          visibleColumns={visibleColumns}
          toggleColumn={toggleColumn}
          onChangeColumnWidth={changeColumnWidth}
          orderBy={orderBy}
          onChangeOrderBy={onChangeOrderBy}
        />
      }
      rowHeight={38}
      tableOffsetTop={tableOffsetTop}
    />
  );
};

export default TradingEventsTable;
