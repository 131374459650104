export const mapProviderToHumanReadable = provider => {
  const rules = {
    betby_trading: 'BETBY trading',
    betgenius: 'BetGenius',
    betradar: 'BetRadar',
    dst: 'DST',
    lsport: 'LSports',
    oddin: 'Oddin',
    sportmarket: 'SportMarket',
    statscore: 'StatScore',
    tradeart: 'TradeArt',
    redfeed: 'RedFeed',
    cns: 'CNS',
    betradar_wac: 'Betradar_Wac',
    betradar_tanktech: 'Betradar_Tanktech',
    betradar_herogaming: 'Betradar_Herogaming',
    betradar_replay: 'Betradar_Replay',
    pandascore: 'PandaScore',
  };

  return rules[provider] || provider;
};
