import { useApolloClient, useMutation } from '@apollo/client';
import { pickBy } from 'lodash';
import { useCallback, useContext } from 'react';
import { ConfirmationPromptContext } from '../../../../providers/ConfirmationPromptProvider';
import cancelBetslips from '../../../gql/Reports/mutations/cancelBetslips';
import getBetsStatus from '../../../gql/Reports/queries/getBetsStatus';
import { useDialog, useTableBulkSelection } from '../../../hooks';
import { BET_STATUS } from '../BetHistory';
import { COLUMN } from '../BetHistoryTable';
import useCancelationNotifications from './useCancelationNotifications';
import CancellationReasonModal from '../../../components/CancellationReasonModal';

const useMultipleBetslipCancel = ({ items }) => {
  const client = useApolloClient();

  const { openConfirmation } = useContext(ConfirmationPromptContext);
  const { open, close } = useDialog({ closeOnEsc: true });

  const [cancelBetslipsCb, { loading: cancelBetslipsLoading }] = useMutation(
    cancelBetslips,
    {
      refetchQueries: ['getBetHistoryList'],
    }
  );

  const bulk = useTableBulkSelection(items, COLUMN.BET_ID, false);
  const chosenIds = pickBy(bulk.chosenItems, value => value);
  const chosenIdsCount = Object.keys(chosenIds).length;

  const { cancelationNotification, errorNotification, getBetsCountTitle } =
    useCancelationNotifications();

  const cancelBetsSubmit = useCallback(
    (items, reason) => {
      const betslipsToCancel = items.map(value => ({
        playerId: value.playerId,
        betId: value.betId,
      }));
      const canceledCount = items.filter(
        value =>
          value.betStatus === BET_STATUS.CANCELED ||
          value.betStatus === BET_STATUS.REJECTED
      ).length;

      const countBetsForCancelation = chosenIdsCount - canceledCount;

      cancelBetslipsCb({
        variables: { betslipsToCancel, cancelReason: reason },
      })
        .then(({ data: { data } = {} }) => {
          cancelationNotification({
            data,
            betsCount: countBetsForCancelation,
            alreadyCanceled: canceledCount,
          });
        })
        .catch(errorNotification)
        .finally(() => {
          bulk.reset();
        });
    },
    [
      cancelBetslipsCb,
      cancelationNotification,
      errorNotification,
      bulk,
      chosenIdsCount,
    ]
  );

  const onCancelBets = useCallback(() => {
    open({
      component: CancellationReasonModal,
      props: {
        close,
        onCancel: () => {
          close();
        },
        onConfirm: reason => {
          client
            .query({
              query: getBetsStatus,
              fetchPolicy: 'no-cache',
              variables: {
                inputFilters: {
                  filterBetId: Object.keys(chosenIds),
                  limit: Object.keys(chosenIds).length,
                },
              },
            })
            .then(
              ({
                data: {
                  data: { items },
                },
              }) => {
                cancelBetsSubmit(items, reason);
              }
            )
            .finally(close);
        },
      },
      options: {
        title: `Cancellation reason of ${getBetsCountTitle(chosenIdsCount)}`,
        onClose: close,
      },
    });
  }, [
    close,
    open,
    chosenIds,
    chosenIdsCount,
    cancelBetsSubmit,
    getBetsCountTitle,
    client,
  ]);

  return {
    bulk,
    cancelBetslipsLoading,
    chosenIdsCount,
    onCancelBets,
  };
};

export default useMultipleBetslipCancel;
