import React from 'react';
import StringField from '../../../components/Fields/StringField/StringField';
import TableHeaderRow from '../../../components/Table/TableHeaderRow';
import FancyTable from '../../../components/Table/FancyTable';
import useTableRowsRender from '../../../hooks/useTableRowsRender';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableGrid from '../../../hooks/useTableGrid';
import useTableColumnsReorder from '../../../hooks/useTableColumnsReorder';
import useTableColumnToggle from '../../../hooks/useTableColumnToggle';
import { tableNames } from '../../../hooks/useLocalStorageTableConfig';

const commonCellStyle = {
  padding: '0px 16px',
  justifyContent: 'center',
};

export const COLUMN = {
  LOW: 'low',
  HIGH: 'high',
};

export const initialToggledColumns = [COLUMN.LOW, COLUMN.HIGH];

export const columnsOrderConfig = [COLUMN.LOW, COLUMN.HIGH];

export const columnsWidthConfig = {
  [COLUMN.LOW]: 1,
  [COLUMN.HIGH]: 1,
};

const MarginLadderDetailsTable = ({ loading, items, rowsMaxHeight }) => {
  const [toggledColumns, toggleColumn] = useTableColumnToggle(
    initialToggledColumns
  );
  const [columnsOrder] = useTableColumnsReorder(columnsOrderConfig);

  const { template, changeColumnWidth } = useTableGrid({
    columns: columnsWidthConfig,
    order: columnsOrder,
    toggledColumns,
    initialColumnWidth: columnsWidthConfig,
    onChangeColumnsWidth: () => {},
  });

  const { availableColumns, visibleColumns } = useTableColumns({
    columns: [
      {
        label: 'Low',
        key: COLUMN.LOW,
        style: commonCellStyle,
        justifyContent: 'center',
        render: data => {
          return <StringField>{data[COLUMN.LOW]}</StringField>;
        },
        resizable: true,
      },
      {
        label: 'High',
        key: COLUMN.HIGH,
        style: commonCellStyle,
        justifyContent: 'center',
        render: data => {
          return <StringField>{data[COLUMN.HIGH]}</StringField>;
        },
        resizable: true,
      },
    ],
    order: columnsOrder,
    toggledColumns,
  });

  const renderRow = useTableRowsRender({
    availableColumns,
    visibleColumns,
    template,
  });

  return (
    <FancyTable
      loading={loading}
      data={items}
      renderRow={renderRow}
      renderHeaderRow={
        <TableHeaderRow
          toggledColumns={toggledColumns}
          toggleColumn={toggleColumn}
          template={template}
          availableColumns={availableColumns}
          visibleColumns={visibleColumns}
          onChangeColumnWidth={changeColumnWidth}
        />
      }
      rowHeight={38}
      rowsMaxHeight={rowsMaxHeight}
    />
  );
};

export default MarginLadderDetailsTable;
