/* eslint-disable no-undef */

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import { isArray, omit, without } from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { Component, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { processUpdate } from '../../actions/dataActions';
import { hideModal } from '../../actions/modalActions';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import fields from '../../components/DynamicForm/fields/index';
import ErrorListTemplate from '../../components/DynamicForm/templates/ErrorListTemplate';
import widgets from '../../components/DynamicForm/widgets/index';
import { useApiHost } from '../../providers/ApiHostProvider';
import { useNotificationPopup } from '../../providers/NotificationPopupProvider';
import { useRoutes } from '../../providers/RoutesProvider';
import { getModal } from '../../reducers/modal';
import withData from '../../uiComponents/withData';
import withUpdate from '../../uiComponents/withUpdate';
import withRouter from '../../utils/withRouter';
import PatchedForm from './PatchedForm';
import { getSubmitMethodByPath, removeOptionalEmptyValues } from './helpers';
import processForm from './processForm';
import ArrayOfFieldsTemplate from './templates/ArrayOfFieldsTemplate';
import FieldTemplate from './templates/FieldTemplate';
import ObjectFieldTemplate from './templates/ObjectFieldTemplate';

const styles = theme => ({
  root: {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
    position: 'relative',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  formMask: {
    position: 'absolute',
    background: theme.palette.background.paper,
    opacity: 0.5,
    left: 0,
    top: 5,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
});

const getTopForError = e => {
  if (!e.property) {
    return null;
  }

  return document
    .getElementById(
      `root_${e.property.slice(1)}`.replace(/]?\.|\[/g, '_').replace(']', '')
    )
    .parentNode.getBoundingClientRect().top;
};

const getLastErrorElement = () => {
  const fieldsWithErrors = document.querySelectorAll('.field-error');

  return fieldsWithErrors ? Array.from(fieldsWithErrors).at(-1) : null;
};

const addErrorsDeep = (externalErrors, errors) => {
  for (let key in externalErrors) {
    if (
      errors[key] &&
      errors[key].addError &&
      Array.isArray(externalErrors[key])
    ) {
      errors[key].addError(externalErrors[key].join(','));
    } else if (
      typeof externalErrors[key] === 'object' &&
      externalErrors[key].constructor === Object &&
      errors[key]
    ) {
      errors[key] = addErrorsDeep(externalErrors[key], errors[key]);
    }
  }

  return errors;
};

const scrollToTop = () => {
  const lastErrorElement = getLastErrorElement();

  if (lastErrorElement) {
    const elementPosition =
      lastErrorElement.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - 64;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

class Form extends Component {
  state = {
    ...processForm(
      this.props.state,
      this.props.schema,
      this.props.uiSchema,
      this.props.data,
      this.props.data,
      this.props.params,
      this.props.formParams,
      this.props.routes
    ),

    externalErrors: {},
  };

  componentDidUpdate(prevProps) {
    const {
      schema,
      uiSchema,
      data,
      formParams = {},
      state,
      params,
      routes,
      operationId,
    } = this.props;
    if (
      (data && prevProps.data !== data) ||
      operationId !== prevProps.operationId
    ) {
      this.setState(
        processForm(
          state,
          schema,
          uiSchema,
          data,
          data,
          params,
          formParams,
          routes
        )
      );
    }
  }

  removeEmptyValues = ({ formData }) => {
    return Object.keys(formData).reduce((acc, key) => {
      const value = formData[key];

      const isValidValue = value !== undefined;

      if (isValidValue) {
        return {
          ...acc,
          [key]: isArray(value) ? without(value, undefined) : value,
        };
      }

      return acc;
    }, {});
  };

  handleChange = ({ formData }) => {
    const {
      schema,
      uiSchema,
      state,
      params,
      formParams = {},
      routes,
    } = this.props;
    const { fullFormData, externalErrors } = this.state;
    const newState = processForm(
      state,
      schema,
      uiSchema,
      formData,
      fullFormData,
      params,
      formParams,
      routes
    );

    for (let key in externalErrors) {
      if (fullFormData[key] && fullFormData[key] !== formData[key]) {
        newState.externalErrors = omit(externalErrors, key);
      }
    }

    this.setState(newState);
  };

  resetForm = () => {
    const {
      schema,
      uiSchema,
      state,
      params,
      formParams = {},
      routes,
    } = this.props;

    const newState = processForm(
      state,
      schema,
      uiSchema,
      {},
      {},
      params,
      formParams,
      routes
    );

    this.setState({
      ...newState,
      externalErrors: {},
    });
  };

  onDataSubmit = async ({ formData }) => {
    const { updateData, processUpdate, path } = this.props;
    const submitMethod = getSubmitMethodByPath(path);

    if (submitMethod === 'post') {
      formData = removeOptionalEmptyValues(formData, this.state.schema);
    }

    const data = await updateData(formData);
    const response = await processUpdate({ data, path });

    if (response?.form_errors || response?.message) {
      this.setState({
        externalErrors:
          typeof response.form_errors === 'string'
            ? JSON.parse(response.form_errors)
            : response.form_errors,
      });
    } else {
      if (submitMethod === 'post') {
        this.resetForm();
      }
    }
  };

  validate = (formData, errors) => {
    const { externalErrors } = this.state;

    return addErrorsDeep(externalErrors, errors);
  };

  render() {
    const { classes, loading, submitting, history, hasUpdate } = this.props;
    const { uiSchema, schema, formData, externalErrors } = this.state;

    return (
      <Paper className={classes.root}>
        {(loading || submitting) && <div className={classes.formMask} />}
        <PatchedForm
          externalErrors={externalErrors}
          validate={this.validate}
          showErrorList
          liveValidate={!isEmpty(externalErrors)}
          noHtml5Validate
          schema={schema}
          uiSchema={uiSchema}
          formData={this.removeEmptyValues({ formData })}
          formContext={{
            formData,
            onChange: (...args) => {
              this.handleChange(...args);
            },
          }}
          FieldTemplate={FieldTemplate}
          ObjectFieldTemplate={ObjectFieldTemplate}
          ArrayFieldTemplate={ArrayOfFieldsTemplate}
          ErrorList={ErrorListTemplate}
          widgets={widgets}
          fields={fields}
          onChange={this.handleChange}
          onSubmit={hasUpdate && this.onDataSubmit}
          onError={scrollToTop}
        >
          <div className={classes.buttonsContainer}>
            {!get(uiSchema, 'ui:options.hide_cancel') && (
              <Button
                className={classes.button}
                onClick={() => {
                  if (this.props.modal.modalType) {
                    this.props.hideModal();
                  } else {
                    this.props.navigate(-1);
                  }
                }}
              >
                Cancel
              </Button>
            )}
            {hasUpdate && (
              <ButtonWithLoader
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
                loading={submitting}
              >
                Submit
              </ButtonWithLoader>
            )}
          </div>
        </PatchedForm>
      </Paper>
    );
  }
}

const withNotificationPopup = BaseComponent => props => {
  const notificationPopup = useNotificationPopup();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routes = useRoutes('data fetcher');
  const { apiHost } = useApiHost('data fetcher');
  const schemaState = {
    routes,
    apiHost,
  };
  const processUpdateCb = useCallback(
    data =>
      dispatch(
        processUpdate(
          {
            ...data,
            notificationPopup,
            navigate,
          },
          schemaState
        )
      ),
    // TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  return (
    <BaseComponent
      processUpdate={processUpdateCb}
      {...props}
      routes={schemaState.routes}
    />
  );
};

export default compose(
  withNotificationPopup,
  withStyles(styles),
  withData,
  withRouter,
  withUpdate,
  connect(
    state => {
      return {
        modal: getModal(state),
        state,
      };
    },
    { hideModal }
  )
)(Form);
