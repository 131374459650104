import { useCallback } from 'react';
import {
  loadFromLocalState,
  saveToLocalState,
  localStorageKeys,
} from '../../store/localStorage';

export const tableConfigKeys = {
  limit: 'limit',
  isPresetsOpened: 'presets-opened',
  isFiltersOpened: 'filters-opened',
  toggledColumns: 'toggled-columns',
};

export const tableNames = {
  GenericTable: 'GenericTable', // default name in case the prop is missing
  RelatedContingencies: 'RelatedContingencies',
  Tournaments: 'Tournaments',
  TemplatesTab: 'TemplatesTab',
  Outcomes: 'Outcomes',
  EventPlayersTab: 'EventPlayersTab',
  GamingActivityTab: 'GamingActivityTab',
  MarketLiabilityTab: 'MarketLiabilityTab',
  TemplateBrands: 'TemplateBrands',
  AutoCreatedTournaments: 'AutoCreatedTournaments',
  BetHistory: 'BetHistory',
  TradingEvents: 'TradingEvents',
  CoverageTab: 'CoverageTab',
  EventManagement: 'EventManagement',
  FailedRequests: 'FailedRequests',
  ChangeLogTab: 'ChangeLogTab',
  LineSettingsTab: 'LineSettingsTab',
  MarginTab: 'MarginTab',
  SourcesTab: 'SourcesTab',
  PromoBanners: 'PromoBanners',
  BetStream: 'BetStream',
  MarginLadders: 'MarginLadders',
};

const tableConfigDefaults = {
  [tableNames.GenericTable]: {
    [tableConfigKeys.limit]: 10,
    [tableConfigKeys.isPresetsOpened]: false,
    [tableConfigKeys.isFiltersOpened]: false,
  },
  [tableNames.LineSettingsTab]: {
    [tableConfigKeys.limit]: 50,
    [tableConfigKeys.isPresetsOpened]: false,
    [tableConfigKeys.isFiltersOpened]: false,
  },
  [tableNames.TemplatesTab]: {
    [tableConfigKeys.limit]: 50,
    [tableConfigKeys.isPresetsOpened]: false,
    [tableConfigKeys.isFiltersOpened]: false,
  },
};

const useLocalStorageTableConfig = tableName => {
  const getTableConfigValue = useCallback(
    key => {
      const tableConfig = loadFromLocalState(localStorageKeys.tableConfig);

      return (
        tableConfig?.[tableName]?.[key] ??
        tableConfigDefaults?.[tableName]?.[key] ??
        tableConfigDefaults[tableNames.GenericTable][key]
      );
    },
    [tableName]
  );

  const setTableConfigValue = useCallback(
    (key, value) => {
      const tableConfig = loadFromLocalState(localStorageKeys.tableConfig);

      saveToLocalState(localStorageKeys.tableConfig, {
        ...(tableConfig || {}),
        [tableName]: {
          ...(tableConfig?.[tableName] || {}),
          [key]: value,
        },
      });
    },
    [tableName]
  );

  return {
    getTableConfigValue,
    setTableConfigValue,
  };
};

export default useLocalStorageTableConfig;
