import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import get from 'lodash/get';
import { styled } from '@mui/system';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import useNavigateWithFrom from '../../../../hooks/useNavigateWithFrom';
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs';
import Card from '../../../components/Card/Card';
import CopyField from '../../../components/Fields/CopyField/CopyField';
import CurrencyField from '../../../components/Fields/CurrencyField/CurrencyField';
import DateField from '../../../components/Fields/DateField/DateField';
import GroupSwitcherField from '../../../components/Fields/GroupSwitcherField/GroupSwitcherField';
import LabeledField from '../../../components/Fields/LabeledField/LabeledField';
import LinkField from '../../../components/Fields/LinkField/LinkField';
import StringField from '../../../components/Fields/StringField/StringField';
import FieldsGroup from '../../../components/FieldsGroup/FieldsGroup';
import NotificationAlert, {
  AlertVariant,
} from '../../../components/Notifications/NotificationAlert';
import Page from '../../../components/Page/Page';
import TabLabel from '../../../components/Tabs/TabLabel';
import Tabs from '../../../components/Tabs/Tabs';
import getEventDetailsQuery from '../../../gql/Reports/queries/getEventDetailsQuery';
import getOperatorEventDetailsQuery from '../../../gql/Reports/queries/getOperatorEventDetailsQuery';
import getEventStatsQuery from '../../../gql/Reports/queries/getEventStatsQuery';
import changeEventAvailability from '../../../gql/mutations/changeEventAvailability';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import {
  useIsAdmin,
  useIsOperator,
  useIsRMAllowed,
} from '../../../providers/OperatorProvider';
import { useToasts } from '../../../providers/ToastsProvider';
import NotFoundPage from '../../NotFoundPage/NotFoundPage';
import TournamentsConfigTable from '../../TournamentsPage/TournamentConfigTable';
import EventBetHistoryTab from './components/EventHistory/EventBetHistoryTab';
import EventPlayersTab from './components/EventPlayers/EventPlayersTab';
import GamingActivityTab from './components/GamingActivity/GamingActivityTab';
import LiabilityMatrixTab from './components/LiabilityMatrix/LiabilityMatrixTab';
import MarketLiabilityTab from './components/MarketLiability/MarketLiabilityTab';
import ApplyRequiredProvider from '../../../providers/ApplyRequiredProvider';
import { tableNames } from '../../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../../components/ErrorWidget';
import { Divider, Stack, Box } from '@mui/material';

const SourcesIdsField = styled(LabeledField)`
  grid-column: span 2;
`;

const EventDetailsTabContent = styled(Box)`
  background-color: #f7f7f7;
  border-radius: 4px;
`;

const Cards = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TournamentConfigContainer = styled('div')`
  background-color: #f7f7f7;
  padding: 8px;
  border-radius: 8px 8px 3px 3px;
`;

const EventRiskManagementCard = styled(Card)`
  padding: 16px 2px 2px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const EventDetails = () => {
  const { eventId, tabKey } = useParams();
  const isOperator = useIsOperator();
  const isRMAllowed = useIsRMAllowed();
  const isAdmin = useIsAdmin();

  const {
    data: { data: eventDetailsData } = {},
    loading: eventDetailsLoading,
    error: eventDetailsError,
  } = useQuery(
    isOperator || !isRMAllowed
      ? getOperatorEventDetailsQuery
      : getEventDetailsQuery,
    {
      variables: {
        eventId: eventId,
      },
    }
  );

  const { data: { data: eventStatsData } = {} } = useQuery(getEventStatsQuery, {
    variables: {
      eventId: eventId,
    },
  });

  const client = useApolloClient();

  const sportId = get(eventDetailsData, 'sport.id');
  const { showToast } = useToasts();
  const [offeredLoading, setOfferedLoading] = useState(false);
  const [changeOffered] = useMutation(changeEventAvailability);

  const setOffered = useCallback(
    offered => {
      setOfferedLoading(true);
      changeOffered({ variables: { eventId, availability: offered } })
        .catch(() => {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR}>
              An error occurred while setting offered/not offered for event
            </NotificationAlert>
          );
        })
        .finally(() => {
          client
            .refetchQueries({
              include: [getEventDetailsQuery],
            })
            .finally(() => {
              setOfferedLoading(false);
            });
        });
    },
    [showToast, client, eventId, changeOffered]
  );

  const crumbs = useBreadcrumbs([
    {
      label:
        eventDetailsData &&
        `${eventDetailsData.sport.name} - ${eventDetailsData.category.name} - ${eventDetailsData.tournament.name} - ${eventDetailsData.event}`,
    },
  ]);

  const navigate = useNavigateWithFrom();

  if (eventDetailsError) {
    return <ErrorWidget />;
  }

  const generateMarketConfigurationLink = data => {
    const brandId = 0;
    const sportId = data.sport.id;
    const categoryId = data.category.id;
    const tournamentId = data.tournament.id;
    const eventId = data.id;

    const url = `/api/v1/SportsbookAdmin/brand/${brandId}/sport/${sportId}/category/${categoryId}/tournament/${tournamentId}/event/${eventId}/markets/get`;

    const getParams = {
      [url]: {
        sport_id: sportId,
        category_id: categoryId,
        tournament_id: tournamentId,
        event_id: eventId,
        brand_id: brandId,
      },
    };

    return `../${url}?jsonQuery=${JSON.stringify(getParams)}`;
  };

  return eventDetailsData ? (
    <Page title={'Event Details'}>
      <BreadCrumbs crumbs={crumbs} />

      <Cards>
        <FieldsGroup>
          <LabeledField label={'Event Date'}>
            <DateField
              date={eventDetailsData.eventScheduled}
              format={'YYYY/MM/DD HH:mm:ss'}
            />
          </LabeledField>

          <LabeledField label={'Event ID'}>
            <CopyField value={eventDetailsData.id}>
              <StringField>{eventDetailsData.id}</StringField>
            </CopyField>
          </LabeledField>

          {!isOperator && isAdmin && isRMAllowed && (
            <div>
              <GroupSwitcherField
                value={eventDetailsData.offered}
                onChange={setOffered}
                disabled={offeredLoading}
                options={[
                  {
                    label: 'Offered',
                    activeColor: 'green',
                    value: true,
                  },
                  {
                    label: 'Not Offered',
                    activeColor: 'red',
                    value: false,
                  },
                ]}
              />
            </div>
          )}
          {!isOperator && isAdmin && isRMAllowed && (
            <SourcesIdsField label={'Event available sources with IDs'}>
              <StringField wrap>
                {eventDetailsData.providers ? (
                  eventDetailsData.providers
                    .map(provider => `${provider.id} (${provider.name})`)
                    .join(', ')
                ) : (
                  <EmptyField />
                )}
              </StringField>
            </SourcesIdsField>
          )}

          {!isOperator && isAdmin && isRMAllowed && (
            <Stack
              divider={<Divider orientation={'vertical'} flexItem />}
              direction={'row'}
              gap={'25px'}
            >
              <LinkField
                href={eventDetailsData.sport && `/event-management`}
                large
                target={'_blank'}
              >
                Event management
              </LinkField>
              {eventDetailsData && (
                <LinkField
                  href={generateMarketConfigurationLink(eventDetailsData)}
                  large
                  target={'_blank'}
                >
                  Market Configuration
                </LinkField>
              )}
            </Stack>
          )}
        </FieldsGroup>

        {eventStatsData && (
          <FieldsGroup>
            <LabeledField label={'GGR Settled'}>
              <CurrencyField currency={'EUR'} amount={eventStatsData.ggr} />
            </LabeledField>

            <LabeledField label={'AVG Bet'}>
              <CurrencyField currency={'EUR'} amount={eventStatsData.avgBet} />
            </LabeledField>

            <LabeledField label={'Max Bet'}>
              <CurrencyField currency={'EUR'} amount={eventStatsData.maxBet} />
            </LabeledField>

            <LabeledField label={'Players'}>
              <StringField>{eventStatsData.players}</StringField>
            </LabeledField>

            <LabeledField label={'Bets'}>
              <StringField>{eventStatsData.bets}</StringField>
            </LabeledField>

            <LabeledField label={'Turnover'}>
              <CurrencyField
                currency={'EUR'}
                amount={eventStatsData.turnover}
              />
            </LabeledField>

            <LabeledField label={'Turnover Open'}>
              <CurrencyField
                currency={'EUR'}
                amount={eventStatsData.turnoverOpen}
              />
            </LabeledField>

            <LabeledField label={'First Bet Date'}>
              <DateField
                date={eventStatsData.firstBetDate}
                format={'YYYY/MM/DD HH:mm:ss'}
              />
            </LabeledField>

            <LabeledField label={'Last Bet Date'}>
              <DateField
                date={eventStatsData.lastBetDate}
                format={'YYYY/MM/DD HH:mm:ss'}
              />
            </LabeledField>

            <LabeledField label={'MAX GGR'}>
              <CurrencyField
                currency={'EUR'}
                amount={eventStatsData.maxGgrEur}
              />
            </LabeledField>

            <LabeledField label={'MIN GGR'}>
              <CurrencyField
                currency={'EUR'}
                amount={eventStatsData.minGgrEur}
              />
            </LabeledField>

            <LabeledField label={'BETS (FREEBET)'}>
              <StringField>{eventStatsData.freebetBets}</StringField>
            </LabeledField>

            <LabeledField label={'PLAYERS (FREEBET)'}>
              <StringField>{eventStatsData.freebetPlayers}</StringField>
            </LabeledField>

            <LabeledField label={'TURNOVER (FREEBET)'}>
              <CurrencyField
                currency={'EUR'}
                amount={eventStatsData.freebetTurnover}
              />
            </LabeledField>

            <LabeledField label={'GGR (FREEBET)'}>
              <CurrencyField
                currency={'EUR'}
                amount={eventStatsData.freebetGgr}
              />
            </LabeledField>
            <LabeledField label={'Canceled bets'}>
              <Stack gap={'5px'} direction={'row'} divider={<Box>/</Box>}>
                <StringField>{eventStatsData.canceledBets}</StringField>
                <CurrencyField
                  currency={'EUR'}
                  amount={eventStatsData.turnoverCanceled}
                />
              </Stack>
            </LabeledField>
          </FieldsGroup>
        )}
        {!isOperator && isAdmin && isRMAllowed && (
          <EventRiskManagementCard>
            <Box
              sx={{
                ml: '26px',
              }}
            >
              <LinkField
                href={`/related-contingencies?filters={"events_ids":["${eventId}"]}`}
                large
              >
                Related Contingencies
              </LinkField>
            </Box>

            <TournamentConfigContainer>
              <TournamentsConfigTable
                tournamentId={eventDetailsData.tournament.id}
              />
            </TournamentConfigContainer>
          </EventRiskManagementCard>
        )}

        <Box
          sx={{
            mt: 2,
          }}
        >
          <Tabs
            activeTabKey={tabKey || 'bet-history'}
            fullWidthTabs
            onChangeTab={tab => {
              navigate(`/events/${eventId}/${tab}`);
            }}
            tabs={[
              {
                key: 'bet-history',
                label: <TabLabel>Event Bet History</TabLabel>,
                content: (
                  <EventDetailsTabContent key={'bet-history'}>
                    <ApplyRequiredProvider tableName={tableNames.BetHistory}>
                      <EventBetHistoryTab />
                    </ApplyRequiredProvider>
                  </EventDetailsTabContent>
                ),
              },
              {
                key: 'players',
                label: <TabLabel>Event Players</TabLabel>,
                content: (
                  <EventDetailsTabContent key={'event-players'}>
                    <EventPlayersTab eventId={eventId} />
                  </EventDetailsTabContent>
                ),
              },
              {
                key: 'gaming-activity',
                label: <TabLabel>Gaming Activity</TabLabel>,
                content: (
                  <EventDetailsTabContent key={'gaming-activity'}>
                    <GamingActivityTab eventId={eventId} />
                  </EventDetailsTabContent>
                ),
              },
              // soccer only
              ...(sportId === '1' && eventStatsData
                ? [
                    {
                      key: 'liability-matrix',
                      label: <TabLabel>Liability Matrix</TabLabel>,
                      content: (
                        <EventDetailsTabContent key="liability-matrix">
                          <LiabilityMatrixTab event={eventStatsData} />
                        </EventDetailsTabContent>
                      ),
                    },
                  ]
                : []),
              {
                key: 'market-liability',
                label: <TabLabel>Market Liability</TabLabel>,
                content: (
                  <EventDetailsTabContent key={'market-liability'}>
                    <MarketLiabilityTab />
                  </EventDetailsTabContent>
                ),
              },
            ]}
          />
        </Box>
      </Cards>
    </Page>
  ) : eventDetailsLoading ? null : (
    <NotFoundPage />
  );
};

export default EventDetails;
