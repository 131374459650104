import { Autocomplete, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import TextField from '../../../../components/Inputs/TextField';
import { useCategories, useLangsAPI } from '../../../../hooks/event-management';
import useDebounced from '../../../../hooks/useDebounced';
import {
  isCustomOption,
  mapDataToAutocompleteValue,
  mapLangsToTranslations,
  sortLanguages,
} from '../../utils';
import TranslationsList from '../TranslationsList';
import ErrorWidget from '../../../../components/ErrorWidget';
import getLanguageInfo from '../../../../gql/EventsGroups/queries/getLanguageInfo';
import EventFormAutocompleteOption from '../EventFormAutocompleteOption';

const CategoryAutocomplete = ({ sportId, languages, predefinedCategory }) => {
  const { setValue, watch } = useFormContext();

  const category = watch('category');
  const tournament = watch('tournament');

  const { getCategoryLangs, error } = useLangsAPI();

  const [inputValue, setInputValue] = useState(predefinedCategory || '');

  const {
    categories,
    refetch: refetchCategories,
    error: categoriesError,
  } = useCategories({
    variables: {
      query: inputValue,
      sportIds: [sportId],
      limit: 15,
    },
  });

  const { data: { getLanguageInfo: languagesData } = {} } =
    useQuery(getLanguageInfo);

  const [isExpandedLanguages, setExpandedLanguages] = useState(false);
  const debouncedCategorySearch = useDebounced(setInputValue, 500);
  useEffect(() => {
    if (inputValue) {
      refetchCategories({ query: inputValue, sportIds: [sportId] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const value = useMemo(() => mapDataToAutocompleteValue(category), [category]);

  const handleApplyCustomValue = async event => {
    const value = event.target.value;
    const primaryTranslation =
      category && category.langs
        ? category.langs.find(l => l.language === 'English')?.translation
        : '';
    const existingOption = categories.find(({ name }) => {
      const segments = name.split('/');
      const label = segments[segments.length - 1].trim();
      return label === value;
    });

    if (value) {
      if (value !== primaryTranslation) {
        if (existingOption) {
          const { data } = await getCategoryLangs({
            sportId,
            categoryId: existingOption.value,
          });
          setValue('category', {
            ...data.getCategoryLangs,
            langs: mapLangsToTranslations(
              sortLanguages(data.getCategoryLangs.langs),
              languagesData
            ),
          });
        } else {
          if (!isCustomOption(tournament)) {
            setValue('tournament', null);
          }

          setValue('category', {
            id: null,
            countryCode: '',
            langs: [
              {
                language: 'English',
                translation: value,
              },
            ],
          });
        }
      }
    } else {
      setValue('category', null);
    }
  };

  if (error || categoriesError) {
    return <ErrorWidget />;
  }

  return (
    <Stack gap={'15px'}>
      <Stack gap={'4px'} direction={'row'}>
        <Stack gap={'2px'} sx={{ width: '100%' }}>
          <Autocomplete
            value={value}
            freeSolo
            filterOptions={options => options}
            getOptionLabel={option => {
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.label;
            }}
            onChange={async (event, newValue, reason) => {
              if (reason === 'selectOption') {
                const { data } = await getCategoryLangs({
                  sportId,
                  categoryId: newValue?.value,
                });
                setValue('category', {
                  ...data.getCategoryLangs,
                  langs: mapLangsToTranslations(
                    sortLanguages(data.getCategoryLangs.langs),
                    languagesData
                  ),
                });
              }
            }}
            onBlur={async event => {
              handleApplyCustomValue(event);
            }}
            options={categories.map(c => ({ label: c.name, value: c.id }))}
            renderOption={(props, option) => (
              <EventFormAutocompleteOption option={option} {...props} />
            )}
            sx={{ width: '100%' }}
            renderInput={params => (
              <TextField
                onKeyDown={async event => {
                  if (event.key === 'Enter' && event.target.value) {
                    handleApplyCustomValue(event);
                  }
                }}
                label={'Category'}
                shrinkable
                helperText={
                  isCustomOption(category)
                    ? 'New category will be added'
                    : value?.value
                }
                FormHelperTextProps={{
                  onClick: e => {
                    e.stopPropagation();
                  },
                  onMouseDown: e => {
                    e.stopPropagation();
                  },
                }}
                {...params}
              />
            )}
            onInputChange={(_, newInputValue) => {
              debouncedCategorySearch(newInputValue);
            }}
          />
        </Stack>
        <TextField
          disabled={!category}
          value={category ? category.countryCode : ''}
          onChange={e =>
            setValue('category.countryCode', e.target.value.toUpperCase())
          }
          sx={{ width: '108px' }}
        />
      </Stack>
      {category?.langs && (
        <TranslationsList
          name={'category'}
          langs={category.langs}
          languages={languages}
          isExpanded={isExpandedLanguages}
          setExpanded={setExpandedLanguages}
          onChange={langs => {
            setValue('category', {
              id: category?.id,
              countryCode: category?.countryCode,
              langs: langs,
            });
          }}
        />
      )}
    </Stack>
  );
};

export default CategoryAutocomplete;
