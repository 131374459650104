import { from, useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import BreadCrumbs from '../../../../../components/BreadCrumbs/BreadCrumbs';
import NotificationAlert, {
  AlertVariant,
} from '../../../../../components/Notifications/NotificationAlert';
import Page from '../../../../../components/Page/Page';
import getLineSettingsById from '../../../../../gql/sportbook-config/line-settings/getLineSettingsById';
import createLineSettings from '../../../../../gql/sportbook-config/line-settings/mutations/createBrandSettings';
import updateLineSettings from '../../../../../gql/sportbook-config/line-settings/mutations/updateLineSettings';
import { useToasts } from '../../../../../providers/ToastsProvider';
import { SETTINGS_FORM_MODE } from '../LineSettingsCreatePage/LineSettingsCreatePage';
import LineSettingsForm from '../components/LineSettingsForm';
import ErrorWidget from '../../../../../components/ErrorWidget';

const LineSettingsEditPage = () => {
  const [updateLineSettingsCallback, { loading: editLoading, error }] =
    useMutation(updateLineSettings);

  const [
    duplicateLineSettingsCallback,
    { loading: duplicateLoading, duplicateError },
  ] = useMutation(createLineSettings);

  const { id } = useParams();
  const { showToast } = useToasts();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const duplicate = searchParams.get('duplicate');

  const lineSettingsData =
    location.state ||
    useQuery(getLineSettingsById, {
      variables: {
        lineSettingsId: id,
      },
    });

  const lineSettings = useMemo(() => {
    const data =
      (lineSettingsData &&
        (lineSettingsData.id
          ? lineSettingsData
          : lineSettingsData.data?.data)) ||
      {};
    if (!isEmpty(data) && duplicate) {
      data.brandIds = [];
    }

    return data;
  }, [lineSettingsData, duplicate]);

  const defaultValues = useCallback(async () => {
    return lineSettings
      ? {
          name: duplicate
            ? lineSettings.name + ' Duplicate'
            : lineSettings.name,
          brandIds: lineSettings.brandIds,
          marginId: lineSettings.margin ? lineSettings.margin.id : null,
          sourcesId: lineSettings.sources ? lineSettings.sources.id : null,
          coverageId: lineSettings.coverage ? lineSettings.coverage.id : null,
          version: lineSettings.version,
        }
      : {};
  }, [lineSettings, duplicate]);

  const onUpdateFormSubmit = useCallback(
    formData => {
      updateLineSettingsCallback({
        variables: {
          LineSettingsId: id,
          input: {
            name: formData.name,
            marginId: formData.marginId,
            sourcesId: formData.sourcesId,
            coverageId: formData.coverageId,
            brands: formData.brands.filter(
              value => !lineSettings.brandIds.includes(value.brandId)
            ),
            version: formData.version,
          },
        },
      })
        .then(data => {
          data
            ? showToast(
                <NotificationAlert
                  variant={AlertVariant.SUCCESS}
                  timeout={5000}
                >
                  Updated line settings: "{formData.name}"
                </NotificationAlert>
              )
            : showToast(
                <NotificationAlert
                  variant={AlertVariant.WARNING}
                  timeout={5000}
                >
                  Update failed
                </NotificationAlert>
              );
          navigate('/line-settings/line');
        })
        .catch(() => {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
              Something went wrong
            </NotificationAlert>
          );
        });
    },
    [updateLineSettingsCallback, navigate, showToast, id, lineSettings]
  );

  const onDuplicateFormSubmit = useCallback(
    formData => {
      const initialAssignedBrands = lineSettings?.brandIds;
      const isBrandAssignedInitially =
        initialAssignedBrands && !isEmpty(initialAssignedBrands);

      duplicateLineSettingsCallback({
        variables: {
          // brandSettingsId: id,
          input: {
            name: formData.name,
            marginId: formData.marginId,
            sourcesId: formData.sourcesId,
            coverageId: formData.coverageId,
            // version:
            brands: isBrandAssignedInitially
              ? formData.brands.filter(
                  value => !initialAssignedBrands.includes(value.brandId)
                )
              : formData.brands,
          },
        },
      })
        .then(data => {
          if (data) {
            showToast(
              <NotificationAlert variant={AlertVariant.SUCCESS} timeout={5000}>
                Duplicated line settings with name: "{formData.name}"
              </NotificationAlert>
            );
          }
          navigate('/line-settings/line');
        })
        .catch(() => {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
              Something went wrong
            </NotificationAlert>
          );
        });
    },
    [duplicateLineSettingsCallback, navigate, showToast, lineSettings?.brandIds]
  );

  if (error || duplicateError) {
    return <ErrorWidget />;
  }

  return (
    <Page title={'Edit Line Settings'}>
      <BreadCrumbs
        crumbs={[
          {
            label: 'Line Settings',
            to: '/line-settings/line',
          },
          {
            label: isEmpty(lineSettings)
              ? '... loading'
              : lineSettings.name + (duplicate ? ' Duplicate' : ''),
          },
        ]}
      />

      <LineSettingsForm
        defaultValues={defaultValues}
        onSubmit={duplicate ? onDuplicateFormSubmit : onUpdateFormSubmit}
        disabledBrands={lineSettings.brandIds}
        formMode={
          duplicate ? SETTINGS_FORM_MODE.DUPLICATE : SETTINGS_FORM_MODE.EDIT
        }
        brandSettingId={id}
      />
    </Page>
  );
};

export default LineSettingsEditPage;
