import React, { useState } from 'react';

import Button from '../Button/Button';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

const REASONS = {
  2: 'CORRECT_SCORE_MISSING',
  3: 'RESULT_UNVERIFIABLE',
  4: 'FORMAT_CHANGE',
  5: 'CANCELLED_EVENT',
  6: 'MISSING_GOALSCORER',
  7: 'MATCH_ENDED_IN_WALKOVER',
  8: 'DEAD_HEAT',
  9: 'RETIRED_OR_DEFAULTED',
  10: 'EVENT_ABANDONED',
  11: 'EVENT_POSTPONED',
  12: 'INCORRECT_ODDS',
  13: 'INCORRECT_STATISTICS',
  14: 'NO_RESULT_ASSIGNABLE',
  16: 'STARTING_PITCHER_CHANGED',
  18: 'INCORRECT_START_TIME',
  19: 'OPERATOR_REQUEST',
  20: 'MERCY_RULE',
  21: 'STREAM_SNIPING',
};

const CancellationReasonModal = ({ onCancel, onConfirm }) => {
  const [selectedReason, setSelectedReason] = useState('2');

  const formatReason = reason => {
    return reason
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/^\w/, char => char.toUpperCase());
  };

  return (
    <Stack gap={'35px'}>
      <FormControl
        sx={{
          gap: '20px',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
          }}
        >
          Select one of the provided reasons for bets cancellation.
        </Typography>
        <RadioGroup
          onChange={(_, v) => setSelectedReason(v)}
          value={selectedReason}
          name="reason"
          sx={{
            maxHeight: '260px',
            gap: '10px',
          }}
        >
          {Object.entries(REASONS).map(([key, value]) => (
            <FormControlLabel
              value={key}
              control={<Radio />}
              label={formatReason(value)}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <Stack direction={'row'} justifyContent={'flex-end'} gap={'15px'}>
        <Button color={'lightBlue'} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant={'contained'}
          onClick={() => onConfirm(+selectedReason)}
        >
          Confirm
        </Button>
      </Stack>
    </Stack>
  );
};

export default CancellationReasonModal;
