import React, { forwardRef } from 'react';
import useAutocompleteQuery from '../../../../hooks/useAutocompleteQuery';
import useSelectCheckValueOptions from '../../../../hooks/useSelectCheckValueOptions';
import useDebounced from '../../../hooks/useDebounced';
import FancySelect from '../FancySelect/FancySelect';
import FancySelectValueProxy from '../FancySelect/FancySelectValueProxy';

const FancyAutocomplete = forwardRef(
  (
    {
      name,
      gqlQuery,
      params,
      label,
      valueKey = 'value',
      labelKey = 'label',
      uniqueKey,
      value = [],
      onChange,
      extraOptions = null,
      proxyValue = false,
      multiple,
      helperText,
      hint,
      disabled,
      checkValueOptions = true,
      prepareOptions,
      skipQuery,
      error,
      allowCustomValueInput = false,
      prepareCustomInput = v => v,
      disableFilterOptions = true,
      ignoreSearchQuery = false,
      alwaysShowClearButton = false,
    },
    ref
  ) => {
    const {
      data: options,
      loading,
      setQuery: onChangeSearch,
    } = useAutocompleteQuery(
      gqlQuery,
      params,
      prepareOptions,
      skipQuery,
      ignoreSearchQuery
    );

    useSelectCheckValueOptions({
      value,
      options,
      valueKey,
      multiple,
      onChange,
      isValueObject: !proxyValue,
      loading,
      check: !allowCustomValueInput && checkValueOptions,
    });

    const debouncedOnChangeSearch = useDebounced(onChangeSearch, 300);

    const Component = (
      <FancySelect
        name={name}
        ref={ref}
        disabled={disabled}
        multiple={multiple}
        labelKey={labelKey}
        valueKey={valueKey}
        uniqueKey={uniqueKey}
        loading={loading}
        label={label}
        onChange={onChange}
        value={value}
        options={options || []}
        onChangeSearch={value => {
          if (allowCustomValueInput) {
            onChange(prepareCustomInput(value));
          }
          debouncedOnChangeSearch(value);
        }}
        extraOptions={extraOptions}
        helperText={helperText || hint}
        error={error}
        disableFilterOptions={disableFilterOptions}
        alwaysShowClearButton={alwaysShowClearButton}
      />
    );

    if (proxyValue) {
      return (
        <FancySelectValueProxy
          multiple={multiple}
          value={value}
          options={options || []}
          onChange={onChange}
          valueKey={valueKey}
        >
          {Component}
        </FancySelectValueProxy>
      );
    }

    return Component;
  }
);

export default FancyAutocomplete;
