import { useCallback, useRef } from 'react';
import BooleanField from '../../components/Fields/BooleanField/BooleanField';
import CopyField from '../../components/Fields/CopyField/CopyField';
import CurrencyField from '../../components/Fields/CurrencyField/CurrencyField';
import DateField from '../../components/Fields/DateField/DateField';
import EmptyField from '../../components/Fields/EmptyField/EmptyField';
import LinkField from '../../components/Fields/LinkField/LinkField';
import StringField from '../../components/Fields/StringField/StringField';
import ExpandableTable from '../../components/Table/ExpandableTable';
import FancyTable from '../../components/Table/FancyTable';
import TableHeaderRow from '../../components/Table/TableHeaderRow';
import useTableColumnToggle from '../../hooks/useTableColumnToggle';
import useTableColumns from '../../hooks/useTableColumns';
import useTableColumnsReorderPreset from '../../hooks/useTableColumnsReorderPreset';
import useTableGrid from '../../hooks/useTableGrid';
import useTableRowsRender from '../../hooks/useTableRowsRender';
import { useIsOperator } from '../../providers/OperatorProvider';
import { useTableBulkContext } from '../../providers/TableBulkProvider';
import BetHistoryDetailsTable from './BetHistoryDetailsTable';
import { tableNames } from '../../hooks/useLocalStorageTableConfig';

export const COLUMN = {
  BET_ID: 'betId',
  PLAYER: 'player',
  CCF: 'ccf',
  BET_STATUS: 'betStatus',
  BET_DATE: 'betTimestamp',
  SETTLE_DATE: 'settleDate',
  EVENT_TIME: 'eventScheduled',
  EVENT: 'event',
  BET_TYPE: 'betType',
  MARKET: 'market',
  OUTCOME: 'outcome',
  ODD: 'odd',
  ODD_RESULT: 'oddResult',
  STAKE: 'stake',
  WON: 'won',
  OPERATOR_BONUS: 'operatorBonus',
  REFUND: 'refund',
  COUNTRY: 'country',
  STAKE_CUR: 'stakeCur',
  WON_CUR: 'wonCur',
  OPERATOR_BONUS_CURRENCY: 'operatorBonusCurrency',
  BET_CURRENCY: 'betCurrency',
  ACD: 'acd',
  FCD: 'fcd',
  RB: 'rb',
  VS: 'vs',
  IP: 'ip',
  BONUS_TYPE: 'bonusType',
  OPERATOR_NAME: 'operatorName',
  BRAND_NAME: 'brandName',
  EXT_PLAYER_ID: 'extPlayerId',
  BONUS_ID: 'bonusId',
  RISK_MANAGEMENT: 'riskManagement',
  USE_MTS: 'useMts',
  CURRENCY_CENTS: 'currencyCents',
  TIMEFRAME_BET_SETTLEMENT_SEC: 'timeframeBetSettlementSec',
  TIMEFRAME_BET_START_HOURS: 'timeframeBetStartsHours',
};

export const columnsWidthConfig = {
  [COLUMN.BET_ID]: '135px',
  [COLUMN.PLAYER]: '135px',
  [COLUMN.CCF]: 0.15,
  [COLUMN.BET_STATUS]: 0.25,
  [COLUMN.BET_DATE]: 0.35,
  [COLUMN.SETTLE_DATE]: 0.35,
  [COLUMN.EVENT_TIME]: 0.35,
  [COLUMN.EVENT]: '200px',
  [COLUMN.BET_TYPE]: 0.25,
  [COLUMN.MARKET]: 0.1,
  [COLUMN.OUTCOME]: 0.6,
  [COLUMN.ODD]: 0.2,
  [COLUMN.ODD_RESULT]: 0.25,
  [COLUMN.STAKE]: 0.2,
  [COLUMN.WON]: 0.2,
  [COLUMN.REFUND]: 0.25,
  [COLUMN.COUNTRY]: 0.25,
  [COLUMN.STAKE_CUR]: 0.25,
  [COLUMN.WON_CUR]: 0.25,
  [COLUMN.OPERATOR_BONUS]: 0.25,
  [COLUMN.OPERATOR_BONUS_CURRENCY]: 0.25,
  [COLUMN.BET_CURRENCY]: 0.25,
  [COLUMN.ACD]: 0.15,
  [COLUMN.FCD]: 0.15,
  [COLUMN.RB]: 0.15,
  [COLUMN.VS]: 0.15,
  [COLUMN.IP]: 0.45,
  [COLUMN.BONUS_TYPE]: 0.25,
  [COLUMN.OPERATOR_NAME]: 0.45,
  [COLUMN.BRAND_NAME]: 0.45,
  [COLUMN.EXT_PLAYER_ID]: 0.5,
  [COLUMN.BONUS_ID]: 0.25,
  [COLUMN.RISK_MANAGEMENT]: 0.45,
  [COLUMN.USE_MTS]: 0.2,
  [COLUMN.CURRENCY_CENTS]: 0.35,
  [COLUMN.TIMEFRAME_BET_SETTLEMENT_SEC]: 0.7,
  [COLUMN.TIMEFRAME_BET_START_HOURS]: 0.7,
};

export const columnsMinWidthConfig = {
  [COLUMN.BET_ID]: '135px',
  [COLUMN.PLAYER]: '135px',
  [COLUMN.CCF]: '55px',
  [COLUMN.BET_STATUS]: '75px',
  [COLUMN.BET_DATE]: '150px',
  [COLUMN.SETTLE_DATE]: '150px',
  [COLUMN.EVENT_TIME]: '150px',
  [COLUMN.EVENT]: '250px',
  [COLUMN.BET_TYPE]: '100px',
  [COLUMN.MARKET]: '100px',
  [COLUMN.OUTCOME]: '100px',
  [COLUMN.ODD]: '80px',
  [COLUMN.ODD_RESULT]: '95px',
  [COLUMN.STAKE]: '75px',
  [COLUMN.WON]: '65px',
  [COLUMN.REFUND]: '75px',
  [COLUMN.COUNTRY]: '80px',
  [COLUMN.STAKE_CUR]: '90px',
  [COLUMN.WON_CUR]: '90px',
  [COLUMN.OPERATOR_BONUS]: '90px',
  [COLUMN.OPERATOR_BONUS_CURRENCY]: '90px',
  [COLUMN.BET_CURRENCY]: '105px',
  [COLUMN.ACD]: '40px',
  [COLUMN.FCD]: '40px',
  [COLUMN.RB]: '40px',
  [COLUMN.VS]: '40px',
  [COLUMN.IP]: '140px',
  [COLUMN.BONUS_TYPE]: '125px',
  [COLUMN.OPERATOR_NAME]: '115px',
  [COLUMN.BRAND_NAME]: '90px',
  [COLUMN.EXT_PLAYER_ID]: '150px',
  [COLUMN.BONUS_ID]: '80px',
  [COLUMN.RISK_MANAGEMENT]: '130px',
  [COLUMN.USE_MTS]: '80px',
  [COLUMN.CURRENCY_CENTS]: '120px',
  [COLUMN.TIMEFRAME_BET_SETTLEMENT_SEC]: '245px',
  [COLUMN.TIMEFRAME_BET_START_HOURS]: '200px',
};

export const columnsOrderConfig = [
  COLUMN.BET_ID,
  COLUMN.PLAYER,
  COLUMN.CCF,
  COLUMN.BET_STATUS,
  COLUMN.BET_DATE,
  COLUMN.SETTLE_DATE,
  COLUMN.EVENT_TIME,
  COLUMN.EVENT,
  COLUMN.BET_TYPE,
  COLUMN.MARKET,
  COLUMN.OUTCOME,
  COLUMN.ODD,
  COLUMN.ODD_RESULT,
  COLUMN.STAKE,
  COLUMN.WON,
  COLUMN.OPERATOR_BONUS,
  COLUMN.REFUND,
  COLUMN.COUNTRY,
  COLUMN.STAKE_CUR,
  COLUMN.WON_CUR,
  COLUMN.OPERATOR_BONUS_CURRENCY,
  COLUMN.BET_CURRENCY,
  COLUMN.ACD,
  COLUMN.FCD,
  COLUMN.RB,
  COLUMN.VS,
  COLUMN.IP,
  COLUMN.BONUS_TYPE,
  COLUMN.OPERATOR_NAME,
  COLUMN.BRAND_NAME,
  COLUMN.EXT_PLAYER_ID,
  COLUMN.BONUS_ID,
  COLUMN.RISK_MANAGEMENT,
  COLUMN.USE_MTS,
  COLUMN.CURRENCY_CENTS,
  COLUMN.TIMEFRAME_BET_SETTLEMENT_SEC,
  COLUMN.TIMEFRAME_BET_START_HOURS,
];

export const initialToggledColumns = [
  COLUMN.BET_ID,
  COLUMN.PLAYER,
  COLUMN.CCF,
  COLUMN.BET_STATUS,
  COLUMN.BET_DATE,
  COLUMN.SETTLE_DATE,
  COLUMN.EVENT_TIME,
  COLUMN.EVENT,
  COLUMN.BET_TYPE,
  COLUMN.MARKET,
  COLUMN.OUTCOME,
  COLUMN.ODD,
  COLUMN.ODD_RESULT,
  COLUMN.STAKE,
  COLUMN.WON,
  COLUMN.OPERATOR_BONUS,
  COLUMN.REFUND,
  // COLUMN.COUNTRY,
  COLUMN.STAKE_CUR,
  COLUMN.WON_CUR,
  COLUMN.OPERATOR_BONUS_CURRENCY,
  COLUMN.BET_CURRENCY,
  COLUMN.ACD,
  COLUMN.FCD,
  COLUMN.RB,
  COLUMN.VS,
  COLUMN.IP,
  COLUMN.BONUS_TYPE,
  // COLUMN.OPERATOR_NAME,
  // COLUMN.BRAND_NAME,
  // COLUMN.EXT_PLAYER_ID,
  // COLUMN.BONUS_ID,
  // COLUMN.RISK_MANAGEMENT,
  // COLUMN.USE_MTS,
  // COLUMN.CURRENCY_CENTS
  // COLUMN.TIMEFRAME_BET_SETTLEMENT_SEC,
  // COLUMN.TIMEFRAME_BET_START_HOURS,
];

const disabledColumnsForOperator = [COLUMN.RISK_MANAGEMENT, COLUMN.USE_MTS];

const BetHistoryTable = ({
  data,
  loading,
  rowActions,
  tableOffsetTop,
  orderBy,
  onChangeOrderBy = () => {},
  isCancelBetsAllowed,
  error,
  isApplyRequired,
}) => {
  const tableRef = useRef(null);
  const bulk = useTableBulkContext();

  const isOperator = useIsOperator();

  const [toggledColumns, toggleColumns] = useTableColumnToggle(
    initialToggledColumns,
    () => {},
    isOperator && disabledColumnsForOperator,
    tableNames.BetHistory
  );

  const renderExpandedRow = useCallback(
    (rowIndex, rowData, onHeightChanged) => {
      return (
        <BetHistoryDetailsTable
          onHeightChanged={onHeightChanged}
          key={rowData[COLUMN.BET_ID]}
          data={rowData.selections}
          isOperator={isOperator}
        />
      );
    },
    [isOperator]
  );

  const [columnsOrder, onReorderColumns] = useTableColumnsReorderPreset(
    isOperator
      ? columnsOrderConfig.filter(
          value => !disabledColumnsForOperator.includes(value)
        )
      : columnsOrderConfig
  );

  const columns = [
    {
      label: 'Bet Id',
      key: COLUMN.BET_ID,
      render: ({ betId }) => {
        return (
          <StringField title={betId}>
            <LinkField href={`/api/v1/BetSlipsAdmin/ch/bet/${betId}/get`} copy>
              {betId}
            </LinkField>
          </StringField>
        );
      },
      sortkey: 'bet_id',
    },
    {
      label: 'Player',
      key: COLUMN.PLAYER,
      render: ({ username, playerId }) => {
        return (
          <StringField title={username}>
            <CopyField value={playerId}>
              <LinkField
                href={`/api/v1/OperatorAPI/players/v3/${playerId}/get?`}
              >
                {username}
              </LinkField>
            </CopyField>
          </StringField>
        );
      },
      sortkey: 'player_id',
    },
    {
      label: 'Ccf',
      key: COLUMN.CCF,
      render: ({ ccf }) => {
        return ccf ? <StringField>{ccf}</StringField> : <EmptyField />;
      },
      sortkey: 'ccf',
    },
    {
      label: 'Status',
      key: COLUMN.BET_STATUS,
      render: ({ betStatus }) => {
        return betStatus ? (
          <StringField>{betStatus}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'bet_status',
    },
    {
      label: 'Bet Date',
      key: COLUMN.BET_DATE,
      render: ({ betTimestamp }) => {
        return betTimestamp ? (
          <StringField>
            <DateField date={betTimestamp} format={'YYYY/MM/DD HH:mm:ss'} />
          </StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'bet_timestamp',
    },
    {
      label: 'Settle Date',
      key: COLUMN.SETTLE_DATE,
      render: ({ settleDate }) => {
        return settleDate ? (
          <StringField>
            <DateField date={settleDate} format={'YYYY/MM/DD HH:mm:ss'} />
          </StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'settle_date',
    },
    {
      label: 'Event Time',
      key: COLUMN.EVENT_TIME,
      render: ({ eventScheduled }) => {
        return eventScheduled ? (
          <StringField>
            <DateField date={eventScheduled} format={'YYYY/MM/DD HH:mm:ss'} />
          </StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'event_scheduled',
    },
    {
      label: 'Event',
      key: COLUMN.EVENT,
      render: ({ event, eventId }) => {
        return eventId ? (
          <StringField title={event}>
            <CopyField value={eventId}>
              <LinkField href={`/events/${eventId}`}>{event}</LinkField>
            </CopyField>
          </StringField>
        ) : (
          <StringField color={'primary'} bold>
            {event}
          </StringField>
        );
      },
      sortkey: 'event_id',
    },
    {
      label: 'Bet Type',
      key: COLUMN.BET_TYPE,
      render: ({ betType, betTypeSpecifier }) => {
        if (!betType) {
          return <EmptyField />;
        }

        if (betType === 'Combo' || betType === 'System') {
          return <StringField>{`${betType} ${betTypeSpecifier}`}</StringField>;
        }

        return <StringField>{betType}</StringField>;
      },
      sortkey: 'bet_type',
    },
    {
      label: 'Market',
      key: COLUMN.MARKET,
      render: rowData => {
        if (rowData.selections?.length === 1) {
          return <StringField>{rowData.selections[0].market}</StringField>;
        }
        return <EmptyField />;
      },
    },
    {
      label: 'Outcome',
      key: COLUMN.OUTCOME,
      render: rowData => {
        if (rowData.selections?.length === 1) {
          return <StringField>{rowData.selections[0].outcome}</StringField>;
        }
        return <EmptyField />;
      },
    },
    {
      label: 'Odd',
      key: COLUMN.ODD,
      justifyContent: 'flex-end',
      render: ({ odd }) => {
        return odd ? (
          <StringField title={odd}>
            {Number(odd).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 3,
            })}
          </StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'odd',
    },
    {
      label: 'Odd Result',
      key: COLUMN.ODD_RESULT,
      justifyContent: 'flex-end',
      render: ({ oddResult }) => {
        return oddResult ? (
          <StringField>{oddResult}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'odd_result',
    },
    {
      label: 'Stake',
      key: COLUMN.STAKE,
      justifyContent: 'flex-end',
      render: ({ stake }) => {
        return stake ? (
          <CurrencyField currency={'EUR'} amount={Number(stake)} />
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'stake',
    },
    {
      label: 'Won',
      key: COLUMN.WON,
      justifyContent: 'flex-end',
      render: ({ won }) => {
        return won ? (
          <CurrencyField currency={'EUR'} amount={Number(won)} />
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'won',
    },
    {
      label: 'Operator Bonus',
      key: COLUMN.OPERATOR_BONUS,
      justifyContent: 'flex-end',
      render: ({ operatorBonus }) => {
        return operatorBonus ? (
          <CurrencyField currency={'EUR'} amount={Number(operatorBonus)} />
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Refund',
      key: COLUMN.REFUND,
      justifyContent: 'flex-end',
      render: ({ refund }) => {
        return refund ? (
          <CurrencyField amount={Number(refund)} />
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'refund',
    },
    {
      label: 'Country',
      key: COLUMN.COUNTRY,
      render: ({ country }) => {
        return country ? <StringField>{country}</StringField> : <EmptyField />;
      },
      sortkey: 'country',
    },
    {
      label: 'Stake (Cur)',
      key: COLUMN.STAKE_CUR,
      justifyContent: 'flex-end',
      render: ({ stakeCurrency, betCurrency }) => {
        return stakeCurrency ? (
          <CurrencyField
            currency={betCurrency}
            amount={Number(stakeCurrency)}
          />
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'stake_currency',
    },
    {
      label: 'Won (Cur)',
      key: COLUMN.WON_CUR,
      justifyContent: 'flex-end',
      render: ({ wonCurrency, betCurrency }) => {
        return wonCurrency ? (
          <CurrencyField currency={betCurrency} amount={Number(wonCurrency)} />
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'won_currency',
    },
    {
      label: 'Operator Bonus (Cur)',
      key: COLUMN.OPERATOR_BONUS_CURRENCY,
      justifyContent: 'flex-end',
      render: ({ operatorBonusCurrency, betCurrency }) => {
        return operatorBonusCurrency ? (
          <CurrencyField
            currency={betCurrency}
            amount={Number(operatorBonusCurrency)}
          />
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Bet Currency',
      key: COLUMN.BET_CURRENCY,
      render: ({ betCurrency }) => {
        return betCurrency ? (
          <StringField>{betCurrency}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'bet_currency',
    },
    {
      label: 'ACD',
      key: COLUMN.ACD,
      style: { textAlign: 'center' },
      render: ({ accepted }) => {
        return <BooleanField active={accepted} />;
      },
      headerTitle: 'Accepted',
      sortkey: 'accepted',
    },
    {
      label: 'FCD',
      key: COLUMN.FCD,
      style: { textAlign: 'center' },
      render: ({ forced }) => {
        return <BooleanField active={forced} />;
      },
      headerTitle: 'Forced',
      sortkey: 'forced',
    },
    {
      label: 'RB',
      key: COLUMN.RB,
      style: { textAlign: 'center' },
      render: ({ hasRollback }) => {
        return <BooleanField active={hasRollback} />;
      },
      headerTitle: 'Has Rollback',
      sortkey: 'has_rollback',
    },
    {
      label: 'VS',
      key: COLUMN.VS,
      style: { textAlign: 'center' },
      render: ({ isVirtual }) => {
        return <BooleanField active={isVirtual} />;
      },
      headerTitle: 'Virtual Sport',
      sortkey: 'is_virtual',
    },
    {
      label: 'Ip',
      key: COLUMN.IP,
      render: ({ ip }) => {
        return ip ? (
          <CopyField value={ip}>
            <StringField title={ip}>{ip}</StringField>
          </CopyField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'ip',
    },
    {
      label: 'Bonus Type',
      key: COLUMN.BONUS_TYPE,
      render: ({ bonusType }) => {
        return bonusType ? (
          <StringField>{bonusType}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'bonus_type',
    },
    {
      label: 'Operator Name',
      key: COLUMN.OPERATOR_NAME,
      render: ({ operatorName }) => {
        return operatorName ? (
          <StringField>{operatorName}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'operator_name',
    },
    {
      label: 'Brand Name',
      key: COLUMN.BRAND_NAME,
      render: ({ brandName }) => {
        return brandName ? (
          <StringField title={brandName}>{brandName}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'brand_name',
    },
    {
      label: 'Ext Player Id',
      key: COLUMN.EXT_PLAYER_ID,
      render: ({ extPlayerId }) => {
        return extPlayerId ? (
          <CopyField value={extPlayerId}>
            <StringField title={extPlayerId}>{extPlayerId}</StringField>
          </CopyField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'ext_player_id',
    },
    {
      label: 'Bonus Id',
      key: COLUMN.BONUS_ID,
      render: ({ bonusId }) => {
        return bonusId ? (
          <StringField title={bonusId}>{bonusId}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'bonus_id',
    },
    {
      label: 'Risk Management',
      key: COLUMN.RISK_MANAGEMENT,
      render: ({ riskManagement }) => {
        return riskManagement ? (
          <StringField>{riskManagement}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'risk_management',
    },
    {
      label: 'Use Mts',
      key: COLUMN.USE_MTS,
      justifyContent: 'flex-end',
      render: ({ useMts }) => {
        return <BooleanField active={useMts} />;
      },
      sortkey: 'use_mts',
    },
    {
      label: 'Currency cents',
      key: COLUMN.CURRENCY_CENTS,
      justifyContent: 'flex-end',
      render: ({ currencyCents }) => {
        return currencyCents ? (
          <StringField>{currencyCents}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'currency_cents',
    },
    {
      label: 'Timeframe (Bet, Settlement), seconds',
      key: COLUMN.TIMEFRAME_BET_SETTLEMENT_SEC,
      justifyContent: 'flex-end',
      render: ({ diffBetSettle }) => {
        return diffBetSettle ? (
          <StringField>{diffBetSettle}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'diff_bet_settle',
    },
    {
      label: 'Timeframe (Bet, Start), hours',
      key: COLUMN.TIMEFRAME_BET_START_HOURS,
      justifyContent: 'flex-end',
      render: ({ diffBetStart }) => {
        return diffBetStart ? (
          <StringField>{diffBetStart}</StringField>
        ) : (
          <EmptyField />
        );
      },
      sortkey: 'diff_bet_start',
    },
  ].map(column => ({ ...column, resizable: true }));

  const { availableColumns, visibleColumns } = useTableColumns({
    columns: isOperator
      ? columns.filter(value => !disabledColumnsForOperator.includes(value.key))
      : columns,
    toggledColumns,
    order: columnsOrder,
  });

  const { template, changeColumnWidth } = useTableGrid({
    columns: columnsWidthConfig,
    order: columnsOrder,
    toggledColumns,
    rowActions: true,
    expandable: true,
    bulk: isCancelBetsAllowed,
    initialColumnWidth: columnsWidthConfig,
    onChangeColumnsWidth: () => {},
    // minWidthConfig: columnsMinWidthConfig,
    //expanded hook with new logic to adjust column sizes by maximal in cell where we need it
    tableRef,
    loading,
    data,
    columnSizesInPixel: true,
  });

  const renderRow = useTableRowsRender({
    availableColumns,
    visibleColumns,
    template,
    rowActions: rowActions,
    bulk: isCancelBetsAllowed && bulk,
  });

  return (
    <ExpandableTable
      id={COLUMN.BET_ID}
      expandAll
      scrollable
      renderExpandedRow={renderExpandedRow}
      loading={loading}
      error={error}
      renderHeaderRow={
        <TableHeaderRow
          sticky={true}
          disabledColumns={isOperator && disabledColumnsForOperator}
          expandAllButtonVisible={false}
          template={template}
          toggledColumns={toggledColumns}
          onReorder={onReorderColumns}
          availableColumns={availableColumns}
          visibleColumns={visibleColumns}
          onChangeColumnWidth={changeColumnWidth}
          toggleColumn={toggleColumns}
          orderBy={orderBy}
          onChangeOrderBy={onChangeOrderBy}
          {...(isCancelBetsAllowed ? { bulk: bulk } : {})}
        />
      }
      renderRow={renderRow}
      data={data}
      tableOffsetTop={tableOffsetTop}
      showApplyRequiredPlaceholder={isApplyRequired}
    >
      <FancyTable tableRef={tableRef} />
    </ExpandableTable>
  );
};

export default BetHistoryTable;
