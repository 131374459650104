import { logout, refreshToken } from '../actions/authActions';
import { showNotification } from '../actions/uiActions';

const apiError = store => next => action => {
  const ret = next(action);

  if (
    action.payload &&
    action.payload.name === 'ApiError' &&
    action.payload.status === 401
  ) {
    store.dispatch(refreshToken());
  }
  if (
    action.payload &&
    action.payload.name === 'ApiError' &&
    action.payload.status === 400
  ) {
    store.dispatch(showNotification('info', action.payload.response.message));
  }
  if (action.payload && action.payload.status === 403) {
    store.dispatch(logout());
  }

  return ret;
};

export default apiError;
