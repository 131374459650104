import { useMutation } from '@apollo/client';
import { capitalize } from 'lodash';
import { memo, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTreeSelectContext } from '../../../../../../../providers/TreeSelectProvider';
import saveSourcesPreset from '../../../../../../gql/sportbook-config/templates/mutations/preset/saveSourcesPreset';
import getSourcesPresetNames from '../../../../../../gql/sportbook-config/templates/queries/getSourcesPresetNames';
import { useToasts } from '../../../../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../../../Notifications/NotificationAlert';
import TextField from '../../../../TextField';
import {
  MenuCaption,
  MenuTitle,
  SavePresetButton,
  SelectContainer,
  VirtualizedMenu,
} from '../../../components';
import { useSourcesTemplateSelect } from '../../../hooks/sources/select/useSourcesTemplateSelect';
import SourcesMarketOption from '../Options/SourcesMarketOption';

const SourcesMarketSelect = memo(
  ({
    nodePath,
    setPath = () => {},
    selectKey,
    nodeIndex,
    options,
    marketType,
  }) => {
    const sportId = nodePath.sportId;
    const selectedId = nodePath.market.marketId;

    const { getValues, setValue } = useFormContext();
    const lineType = getValues('lineType');
    const { preset, setPreset, presetApplied, setPresetApplied } =
      useTreeSelectContext();

    const {
      displayOptions,
      onSearchFieldChange,
      applySourcesPresets,
      isSourcesWriter,
      isPresetsAvailable,
    } = useSourcesTemplateSelect({
      options,
      setFormValue: setValue,
      getFormValues: getValues,
    });

    const [saveSourcesPresetCallback] = useMutation(saveSourcesPreset);
    const { showToast } = useToasts();

    const onSourcesPresetSave = useCallback(
      name => {
        const template = getValues('template');
        const commands = Object.values(template).flat();

        //send only commands for markets
        const presetCommands = commands.reduce((accumulator, currentValue) => {
          if (currentValue.nodeSelector.market) {
            return [
              ...accumulator,
              {
                nodeSelector: currentValue.nodeSelector,
                provider: currentValue.provider,
                priority: currentValue.priority,
                enabled: currentValue.enabled,
              },
            ];
          }
          return accumulator;
        }, []);

        saveSourcesPresetCallback({
          variables: {
            input: {
              name,
              sportId,
              presets: presetCommands,
            },
          },
        })
          .then(data => {
            data &&
              showToast(
                <NotificationAlert
                  variant={AlertVariant.SUCCESS}
                  timeout={2000}
                >
                  Preset saved successfully
                </NotificationAlert>
              );
          })
          .catch(() => {
            showToast(
              <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
                Something went wrong
              </NotificationAlert>
            );
          });
      },
      [sportId, showToast, saveSourcesPresetCallback, getValues]
    );

    useEffect(() => {
      applySourcesPresets({
        preset,
        setPresetApplied,
        setPreset,
        pathToSelect: {
          sportId: nodePath.sportId,
          categoryId: nodePath.categoryId,
        },
      });
    }, [preset, setPresetApplied, setPreset, applySourcesPresets, nodePath]);

    return (
      sportId && (
        <SelectContainer style={{ width: '350px' }}>
          <TextField
            style={{ paddingBottom: 16, width: 'calc(100% - 12px)' }}
            label={`Search ${capitalize(marketType)}'s Market`}
            onChange={onSearchFieldChange}
            clearButton={true}
          />

          <MenuCaption>
            <MenuTitle name={'Market'} />
          </MenuCaption>
          <VirtualizedMenu
            containerStyle={{
              height: 'calc(100% - 175px)',
            }}
            rowCount={displayOptions.length}
            itemRender={({ measure, index }) => {
              const option = displayOptions[index];

              return (
                <SourcesMarketOption
                  key={option.marketId}
                  nodePath={nodePath}
                  selectKey={selectKey}
                  setPath={setPath}
                  nodeIndex={nodeIndex}
                  selected={option.marketId === selectedId}
                  itemValue={option}
                  onMenuOpen={measure}
                  lineType={lineType}
                  configurationDisabled={!isSourcesWriter}
                />
              );
            }}
          />
          {isPresetsAvailable && (
            <SavePresetButton
              templateType={'source'}
              getPresetNamesQuery={getSourcesPresetNames}
              sportId={sportId}
              onSave={onSourcesPresetSave}
            />
          )}
        </SelectContainer>
      )
    );
  }
);

export default SourcesMarketSelect;
