import { useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import classNames from 'clsx';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import { SCHEMA_LABELS, SHOW_DEV_SECTION } from '../constants/schemaUrls';
import MainContainer from '../containers/MainContainer';
import ModalRoot from '../containers/ModalRoot';
import TopMenuContainer from '../containers/TopMenuContainer';
import useDrawerLeftShift from '../hooks/useDrawerLeftShift';
import { useSessionStorage } from '../hooks/useStorage';
import BurgerIcon from '../icons/controls/BurgerIcon';
import AppBarRefProvider from '../providers/AppBarRefProvider';
import { useAppPageTitle } from '../providers/AppPageTitleProvider';
import { isDrawerOpen } from '../reducers/drawerOpen';
import { getSchemaUrls } from '../reducers/schemaUrls';
import { isOldVersionRoute } from '../utils/routes';
import { getRouteByPathname } from '../utils/schemaHelper';
import Page from '../v2/components/Page/Page';
import ConnectionStatusBar from './ConnectionStatusBar';
import DrawerHeader from './DrawerHeader';
import DrawerMenu from './DrawerMenu';
import DialogProvider from '../v2/providers/DialogProvider';
export const GLOBAL_DRAWER_WIDTH = 300;

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {},
  appBarShift: {
    width: `calc(100% - ${GLOBAL_DRAWER_WIDTH}px)`,
    marginLeft: GLOBAL_DRAWER_WIDTH,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: GLOBAL_DRAWER_WIDTH,
  },
  drawerPaper: {
    width: GLOBAL_DRAWER_WIDTH,
  },
  drawerHeader: {
    minHeight: '48px',
  },
  headerTitle: {
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    fontSize: '24px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
  logo: {
    width: '80%',
  },
  main: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 0,
    maxWidth: '100%',
  },
  menuIcon: {
    fontSize: '16px',
  },
  mainContent: {
    flex: 1,
  },
  closeDrawerButton: {},
  [theme.breakpoints.up('md')]: {
    main: {
      marginLeft: -GLOBAL_DRAWER_WIDTH,
      transition: theme.transitions.create(['margin', 'width'], {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
    },
    mainShift: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    mainContent: {},
  },
});

const getGroups = routes => {
  let initial = {};

  if (SCHEMA_LABELS && SHOW_DEV_SECTION) {
    initial = {
      Dev: {
        order: 99,
        label: 'Dev',
        icon: 'Scanner Feeds',
        items: [
          {
            label: 'Margin ladders',
            link: '/trading-margin-ladders',
          },
          {
            label: 'Betstream v2',
            link: '/betstream',
          },
          {
            label: 'Bet History v2',
            link: '/bet-history',
          },
          {
            label: 'Auto-created Tournaments',
            link: '/auto-created-tournaments',
          },
          {
            label: 'Swagger UI',
            link: '/dev/schema/swagger-ui',
          },
          {
            label: 'Storybook',
            link: '/storybook',
          },
          {
            label: 'Raw Schema',
            link: '/dev/app/schema',
          },

          {
            label: 'Documentation',
            link: '/dev/schema/documentation',
          },
          {
            label: 'Sandbox',
            link: '/dev/form/json/default',
          },
        ],
      },
    };
  }

  const groups = Object.keys(routes).reduce((res, routeName) => {
    const { summary, responses } = routes[routeName];

    const group = get(
      responses,
      '200.content.application/json.x-ui-schema.ui:menu'
    );

    const groupIcon = get(
      responses,
      '200.content.application/json.x-ui-schema.ui:menu_icon'
    );

    const groupOrder = get(
      responses,
      '200.content.application/json.x-ui-schema.ui:menu_group_order',
      0
    );

    const order = get(
      responses,
      '200.content.application/json.x-ui-schema.ui:menu_order'
    );

    const badge = get(
      responses,
      '200.content.application/json.x-ui-schema.ui:menu_badge'
    );

    const menuLink = get(
      responses,
      '200.content.application/json.x-ui-schema.ui:menu_link'
    );

    if (group) {
      const data = {
        label: `${summary}`,
        link: menuLink || routeName,
        initialQuery: '',
        order,
        badge,
      };

      res[group] = res[group]
        ? {
            ...res[group],
            icon: res[group].icon || groupIcon,
            items: [...res[group].items, data],
          }
        : {
            // TODO remove custom route
            items: [data],
            icon: groupIcon,
            order: groupOrder,
          };

      res[group].items = orderBy(res[group].items, 'order', 'ASC');
    }

    return res;
  }, initial);

  return sortBy(
    Object.keys(groups).map(groupName => {
      return {
        ...groups[groupName],
        label: groupName,
      };
    }),
    ['order', 'label']
  );
};

const Root = ({
  routes,
  toggleDrawer,
  activeSchemaUrl,
  schemaUrls,
  addSchemaUrl,
  classes,
}) => {
  const drawerOpen = useSelector(isDrawerOpen);
  const savedSchemaUrls = useSelector(getSchemaUrls);
  const location = useLocation();
  const appBarRef = useRef(null);

  const theme = useTheme();
  const mdMatch = useMediaQuery(theme.breakpoints.up('md'));

  //was 24, with 24 page has useless horizontal scroll
  const rightShift = 9;
  const leftShift = useDrawerLeftShift();

  const [sessionSchemaUrl, setSessionSchemaUrl] = useSessionStorage(
    'sessionSchemaUrl',
    null
  );

  const isOldVersionOfRoute = isOldVersionRoute(location);

  const currentRoute = getRouteByPathname(routes || {}, location.pathname);

  const { title: pageTitle } = useAppPageTitle(
    currentRoute && currentRoute.summary
  );

  useEffect(() => {
    if (!sessionSchemaUrl) {
      setSessionSchemaUrl(savedSchemaUrls[0]);
    }
  }, [sessionSchemaUrl, setSessionSchemaUrl, savedSchemaUrls]);

  return (
    <AppBarRefProvider value={appBarRef.current}>
      <div className={classes.root}>
        <DialogProvider />
        <CssBaseline />
        <ModalRoot />
        <>
          <AppBar
            ref={appBarRef}
            position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: mdMatch && drawerOpen,
            })}
            elevation={0}
          >
            <Toolbar className={classes.toolbar}>
              {!drawerOpen && (
                <IconButton
                  edge="start"
                  aria-label="Toggle Menu"
                  color="inherit"
                  onClick={() => toggleDrawer()}
                  size="large"
                >
                  <BurgerIcon className={classes.menuIcon} />
                </IconButton>
              )}

              <Typography
                variant="inherit"
                noWrap
                color="inherit"
                className={classes.headerTitle}
              >
                {pageTitle}
              </Typography>

              <TopMenuContainer routes={routes} />
            </Toolbar>
            <ConnectionStatusBar />
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant={mdMatch ? 'persistent' : 'temporary'}
            open={drawerOpen}
            onClose={() => toggleDrawer()}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <DrawerHeader toggleDrawer={toggleDrawer} />

            <DrawerMenu
              loading={!routes}
              {...(!mdMatch && {
                onMenuItemClick: () => toggleDrawer(),
              })}
              groups={routes ? getGroups(routes) : []}
              search={location.search}
              activeRoute={location.pathname}
              activeSchemaUrl={activeSchemaUrl}
              schemaUrls={schemaUrls}
              fetchSchema={addSchemaUrl}
            />
          </Drawer>
        </>

        <main
          className={classNames(classes.main, {
            [classes.mainShift]: mdMatch && drawerOpen,
          })}
        >
          <div className={classes.drawerHeader} />
          <div
            className={classes.mainContent}
            style={{
              width: `calc(100vw - ${leftShift - rightShift}px)`,
            }}
          >
            {isOldVersionOfRoute ? (
              <Page title={pageTitle}>
                <MainContainer routes={routes} />
              </Page>
            ) : (
              <MainContainer routes={routes} />
            )}
          </div>
        </main>
      </div>
    </AppBarRefProvider>
  );
};

export default compose(withStyles(styles))(Root);
