export const mapDataToAutocompleteValue = data => {
  return data && data.langs
    ? {
        label:
          data.langs.find(l => l.language === 'English')?.translation || '',
        value: data.id,
      }
    : null;
};

// "langs" is referring to the data from api, and "languagesList" is just a list of all available languages
export const mapTranslationsToLangs = (langs, languagesList) => {
  return langs.reduce((acc, lang) => {
    const language = languagesList.find(l => l.name === lang.language);
    if (language) {
      acc[language.code] = lang.translation;
    }
    return acc;
  }, {});
};

export const mapLangsToTranslations = (langs, languagesList) => {
  return langs
    ? Object.keys(langs)
        .filter(
          k => k !== 'undefined' && !!languagesList.find(l => l.code === k)
        )
        .map(key => {
          const { name } = languagesList.find(l => l.code === key);

          return {
            language: name,
            translation: langs[key],
          };
        })
    : [];
};

export const eventSourcesMapping = {
  betradar: 'BR',
  betgenius: 'BG',
  sportmarket: 'SM',
  lsport: 'LS',
  tradeart: 'TA',
  betby_trading: 'Betby',
  oddin: 'Oddin',
  dst: 'DST',
  redfeed: 'RF',
  statscore: 'SS',
  pandascore: 'Panda',
};

export const sortLanguages = langs => {
  const { en, ...rest } = langs;
  return { en, ...rest };
};

export const sortLanguagesList = (langs, valueKey = 'name') => {
  if (langs.length) {
    const english = langs.find(
      lang => lang[valueKey].toLowerCase() === 'english'
    );

    const rest = langs.filter(
      lang => lang[valueKey].toLowerCase() !== 'english'
    );

    return [
      ...(english && [english]),
      ...rest.sort((a, b) => a[valueKey].localeCompare(b[valueKey])),
    ];
  }

  return langs;
};

export const isCustomOption = data => {
  return data && !data.id && data.langs?.[0]?.translation;
};
