import { useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import TreeSelectProvider from '../../../../../../providers/TreeSelectProvider';
import BreadCrumbs from '../../../../../components/BreadCrumbs/BreadCrumbs';
import NotificationAlert, {
  AlertVariant,
} from '../../../../../components/Notifications/NotificationAlert';
import createCoverageTemplate from '../../../../../gql/sportbook-config/templates/mutations/create/createCoverageTemplate';
import createMarginTemplate from '../../../../../gql/sportbook-config/templates/mutations/create/createMarginTemplate';
import createSourcesTemplate from '../../../../../gql/sportbook-config/templates/mutations/create/createSourcesTemplate';
import updateCoverageTemplate from '../../../../../gql/sportbook-config/templates/mutations/update/updateCoverageTemplate';
import updateMarginTemplate from '../../../../../gql/sportbook-config/templates/mutations/update/updateMarginTemplate';
import updateSourcesTemplate from '../../../../../gql/sportbook-config/templates/mutations/update/updateSourcesTemplate';
import getTemplatesTable from '../../../../../gql/sportbook-config/templates/queries/getTemplatesTable';
import { withoutEmpty } from '../../../../../hooks/usePresetFilters';
import { useToasts } from '../../../../../providers/ToastsProvider';
import { SETTINGS_FORM_MODE } from '../../LineSettings/LineSettingsCreatePage/LineSettingsCreatePage';
import { LINE_TYPES } from '../components/TemplateConfigurationField';
import TemplateForm from '../components/TemplateForm';
import TemplateFormPage from '../components/TemplateFormPage';
import { getSubmitMutation } from '../utils/getSubmitMutation';
import { getTemplateInputData } from '../utils/getTemplateInputData';
import ErrorWidget from '../../../../../components/ErrorWidget';

const TemplateEditPage = () => {
  const { id } = useParams();
  const { showToast } = useToasts();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [updateSourcesTemplateCallback, { error: updateSourcesError }] =
    useMutation(updateSourcesTemplate);
  const [updateCoverageTemplateCallback, { error: updateCoverageError }] =
    useMutation(updateCoverageTemplate);
  const [updateMarginTemplateCallback, { error: updateMarginError }] =
    useMutation(updateMarginTemplate);

  //to duplicate template we have to pass parentId to crate mutation
  const [duplicateSourcesTemplateCallback, { error: duplicateSourcesError }] =
    useMutation(createSourcesTemplate);
  const [duplicateCoverageTemplateCallback, { error: duplicateCoverageError }] =
    useMutation(createCoverageTemplate);
  const [duplicateMarginTemplateCallback, { error: duplicateMarginError }] =
    useMutation(createMarginTemplate);

  const duplicate = searchParams.get('duplicate');

  const templateData =
    location.state && location.state.id
      ? location.state
      : useQuery(getTemplatesTable, {
          variables: {
            filters: { templateIds: [id], limit: 0, offset: 0 },
          },
        });

  const template = useMemo(() => {
    return (
      (templateData &&
        (templateData.id
          ? templateData
          : templateData.data && templateData.data.data.items[0])) ||
      {}
    );
  }, [templateData]);

  const defaultValues = useCallback(async () => {
    return template
      ? {
          parentId: template.parent_id,
          name: duplicate ? template.name + ' Duplicate' : template.name,
          type: template.type,
          lineType: LINE_TYPES.prematch,
          configLineType: LINE_TYPES.prematch,
          version: template.version,
          template: {},
        }
      : {};
  }, [template, duplicate]);

  const onTemplateEdit = useCallback(
    formData => {
      const input = getTemplateInputData({ formData });
      const templateType = formData.type;

      const submitMutation = getSubmitMutation(
        templateType,
        updateSourcesTemplateCallback,
        updateCoverageTemplateCallback,
        updateMarginTemplateCallback
      );

      submitMutation({
        variables: {
          templateId: id,
          input: withoutEmpty(input),
        },
      })
        .then(data => {
          data &&
            showToast(
              <NotificationAlert variant={AlertVariant.SUCCESS} timeout={2000}>
                <b>{formData.name}</b> template edited
              </NotificationAlert>
            );
          navigate('/line-settings/template');
        })
        .catch(() => {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
              Something went wrong
            </NotificationAlert>
          );
        });
    },
    [
      id,
      showToast,
      navigate,
      updateSourcesTemplateCallback,
      updateCoverageTemplateCallback,
      updateMarginTemplateCallback,
    ]
  );

  const onTemplateDuplicate = useCallback(
    formData => {
      const input = getTemplateInputData({ formData });
      const templateType = formData.type;

      const submitMutation = getSubmitMutation(
        templateType,
        duplicateSourcesTemplateCallback,
        duplicateCoverageTemplateCallback,
        duplicateMarginTemplateCallback
      );

      submitMutation({
        variables: {
          parentId: id,
          input: withoutEmpty(input),
        },
      })
        .then(data => {
          data &&
            showToast(
              <NotificationAlert variant={AlertVariant.SUCCESS} timeout={2000}>
                <b>{formData.name}</b> duplicate created
              </NotificationAlert>
            );
          navigate('/line-settings/template');
        })
        .catch(() => {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
              Something went wrong
            </NotificationAlert>
          );
        });
    },
    [
      id,
      showToast,
      navigate,
      duplicateSourcesTemplateCallback,
      duplicateCoverageTemplateCallback,
      duplicateMarginTemplateCallback,
    ]
  );

  if (
    updateSourcesError ||
    updateCoverageError ||
    updateMarginError ||
    duplicateSourcesError ||
    duplicateCoverageError ||
    duplicateMarginError
  ) {
    return <ErrorWidget />;
  }

  return (
    <TreeSelectProvider>
      <TemplateFormPage title={'Edit Template'}>
        <BreadCrumbs
          crumbs={[
            {
              label: 'Line Settings',
              to: '/line-settings/template',
            },
            {
              label: isEmpty(template)
                ? '... loading'
                : template.name + (duplicate ? ' Duplicate' : ''),
            },
          ]}
        />

        {!isEmpty(template) && (
          <TemplateForm
            defaultValues={defaultValues}
            templateId={id}
            defaultTemplateType={template.type}
            formMode={
              duplicate ? SETTINGS_FORM_MODE.DUPLICATE : SETTINGS_FORM_MODE.EDIT
            }
            onSubmit={duplicate ? onTemplateDuplicate : onTemplateEdit}
          />
        )}
      </TemplateFormPage>
    </TreeSelectProvider>
  );
};

export default TemplateEditPage;
