import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { capitalize, isEmpty, isEqual, omit, remove, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  initialPreset,
  useTreeSelectContext,
} from '../../../../../../../providers/TreeSelectProvider';
import saveCoveragePreset from '../../../../../../gql/sportbook-config/templates/mutations/preset/saveCoveragePreset';
import getCoveragePresetNames from '../../../../../../gql/sportbook-config/templates/queries/getCoveragePresetNames';
import { withoutEmpty } from '../../../../../../hooks/usePresetFilters';
import {
  ACCESS_ROLE,
  useSportsBookUserAccessRoles,
} from '../../../../../../providers/SportsBookUserAccessRolesProvider';
import { useToasts } from '../../../../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../../../Notifications/NotificationAlert';
import TabLabel from '../../../../../Tabs/TabLabel';
import Tabs from '../../../../../Tabs/Tabs';
import {
  DisabledItemArea,
  DraggableSelectMenu,
  InfiniteMenu,
  MenuCaption,
  MenuTitle,
  SavePresetButton,
  SelectContainer,
  SelectSearch,
  VirtualizedMenu,
} from '../../../components/';
import { useCoverageMarketsFetch } from '../../../hooks/coverage/select/market/useCoverageMarketsFetch';
import { getKeySelector } from '../../../utils/getKeySelector';
import { applyCoveragePresets } from '../CoverageTreeSelect';
import CoverageMarketOptionComponent from '../Options/CoverageMarketOptionComponent';
import { useMarketsReorder } from '../../../hooks/coverage/select/market/useMarketsReorder';

const CoverageMarketSelect = ({
  selectKey,
  nodeIndex,
  options,
  marketType,
}) => {
  const { id: templateId } = useParams();
  const { getValues, setValue } = useFormContext();

  const [template, lineType, switchedFrom] = getValues([
    'template',
    'lineType',
    'switchedFrom',
  ]);

  const { roles } = useSportsBookUserAccessRoles();
  //TODO by hook
  const isCoverageWriter = roles.coverage === ACCESS_ROLE.WRITE;
  const isPresetsAvailable =
    roles?.coverage === ACCESS_ROLE.WRITE &&
    roles?.sources === ACCESS_ROLE.WRITE;

  const [saveCoveragePresetCallback] = useMutation(saveCoveragePreset);

  const { showToast } = useToasts();

  const {
    preset,
    setPreset,
    nodePath: { [lineType]: nodePath },
  } = useTreeSelectContext();

  const sportId = nodePath.sportId;
  const selectedId = nodePath.market.marketId;

  const [tabKey, setTabKey] = useState('allMarkets');

  const [allMarkets, setAllMarkets] = useState(options['allMarkets']);
  const [mainMarkets, setMainMarkets] = useState(options['mainMarkets']);

  const [searchParams] = useSearchParams();
  const duplicate = !!searchParams.get('duplicate');

  const displayOptions = useMemo(() => {
    return tabKey === 'allMarkets' ? allMarkets : mainMarkets;
  }, [tabKey, allMarkets, mainMarkets]);
  const [dragDropDisabled, setDragDropDisabled] = useState(false);
  const [isNestedDragDropListsAvailable, setIsNestedDragDropListsAvailable] =
    useState(true);

  //we need to store main markets in ref to filter while search using this value
  const mainMarketsRef = useRef([]);

  const searchValueRef = useRef('');

  const onMarketsPageFetchRef = useRef(() => {});

  const { fetchMarketsPage, loadRows, loading, marketsPaginationRef } =
    useCoverageMarketsFetch({
      setAllMarkets,
      tabKey,
      duplicate,
      templateId,
      nodePath,
      lineType,
      options,
      displayOptions,
      searchValue: searchValueRef.current,
      onMarketsPageFetch: onMarketsPageFetchRef.current,
    });

  const { moveItem, inheritOrder } = useMarketsReorder(marketType);

  const tabs = [
    {
      key: 'allMarkets',
      label: <TabLabel>All Markets</TabLabel>,
    },
    {
      key: 'mainMarkets',
      label: <TabLabel>Main Markets</TabLabel>,
    },
  ];

  const onCoveragePresetSave = useCallback(
    name => {
      const template = getValues('template');
      const commands = Object.values(template);

      const presetCommands = commands.reduce((accumulator, currentValue) => {
        if (
          currentValue.nodeSelector.market &&
          currentValue.nodeSelector.market.marketId
        ) {
          const presetValue = {
            nodeSelector: currentValue.nodeSelector,
            status: currentValue.status,
            priority: currentValue.priority,
            groups: currentValue.groups,
            cashOut: currentValue.cashOut,
            mainMarketPriority: currentValue.mainMarketPriority,
          };

          return [...accumulator, presetValue];
        }
        return accumulator;
      }, []);

      saveCoveragePresetCallback({
        variables: {
          input: {
            name,
            sportId,
            presets: presetCommands,
          },
        },
      })
        .then(data => {
          data &&
            showToast(
              <NotificationAlert variant={AlertVariant.SUCCESS} timeout={2000}>
                Preset saved successfully
              </NotificationAlert>
            );
        })
        .catch(() => {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
              Something went wrong
            </NotificationAlert>
          );
        });
    },
    [sportId, showToast, saveCoveragePresetCallback, getValues]
  );

  //apply changes for each option (to save order)
  const applyTemplateConfigs = useCallback(
    (tabKey, setDisplayOptions, targetValues) => {
      const template = getValues('template');
      setDisplayOptions(currentOptions => {
        const newValues = targetValues || currentOptions || [];

        const path =
          tabKey === 'allMarkets'
            ? withoutEmpty({
                sportId: nodePath.sportId,
                categoryId: nodePath.tournamentId
                  ? undefined
                  : nodePath.categoryId,
                tournamentId: nodePath.tournamentId,
                eventId: nodePath.eventId,
              })
            : { sportId: nodePath.sportId };

        if (!isEmpty(template)) {
          Object.values(template).forEach(value => {
            if (lineType === value.lineType && value.nodeSelector.market) {
              const nodeSelector = value.nodeSelector;

              const priorityKey =
                tabKey === 'allMarkets' ? 'priority' : 'mainMarketPriority';
              const optionIndex = newValues.findIndex(option =>
                isEqual(
                  {
                    ...path,
                    market: {
                      marketId: option.marketId,
                    },
                  },
                  nodeSelector
                )
              );
              //if item priority saved, we need to 'move' this item on its new place
              if (optionIndex !== -1 && value[priorityKey]) {
                const option = newValues[optionIndex];

                if (value[priorityKey] !== optionIndex + 1) {
                  moveItem(
                    newValues,
                    option,
                    value[priorityKey] - 1,
                    nodeSelector.market.marketId
                  );
                }
              }
            }
          });

          if (
            marketType !== 'sport' &&
            tabKey === 'allMarkets' &&
            newValues &&
            options.marketsParentInherit
          ) {
            inheritOrder({
              options: newValues,
              template,
              lineType,
              path: nodePath,
            });
          }

          //after items move we need to change items priority according to position
          return newValues.map((value, index) => ({
            ...value,
            priority: index + 1,
          }));
        } else {
          return newValues;
        }
      });
    },
    [
      lineType,
      nodePath,
      getValues,
      inheritOrder,
      marketType,
      moveItem,
      options.marketsParentInherit,
    ]
  );

  onMarketsPageFetchRef.current = useCallback(
    () =>
      applyTemplateConfigs(
        tabKey,
        tabKey === 'allMarkets' ? setAllMarkets : setMainMarkets
      ),
    [tabKey, applyTemplateConfigs]
  );

  const addMainMarkets = ({ accumulator, currentValue, priority }) => {
    if (accumulator.some(value => value.marketId === currentValue.marketId)) {
      return accumulator;
    }
    return [
      ...accumulator,
      {
        marketId: currentValue.marketId,
        name: currentValue.name,
        priority: priority,
      },
    ];
  };

  const changeMainMarkets = useCallback(() => {
    const template = getValues('template');

    const newMainMarkets = allMarkets.reduce((accumulator, currentValue) => {
      const rootNodeSelector = {
        sportId: nodePath.sportId,
        market: {
          marketId: currentValue.marketId,
        },
      };

      const rootKeySelector = getKeySelector({
        nodeSelector: rootNodeSelector,
        lineType,
      });

      const rootConfig = template[rootKeySelector];

      if (rootConfig) {
        return rootConfig.mainMarketPriority
          ? addMainMarkets({
              accumulator,
              currentValue,
              priority: rootConfig.mainMarketPriority,
            })
          : accumulator.filter(
              value => value.marketId !== currentValue.marketId
            );
      }
      return accumulator;
    }, mainMarkets);

    const sortedNewMainMarkets = sortBy(newMainMarkets, ['priority']);

    setMainMarkets(sortedNewMainMarkets);
  }, [allMarkets, getValues, lineType, mainMarkets, nodePath]);

  const changeTabContent = useCallback(
    key => {
      searchValueRef.current = null;

      if (key === 'mainMarkets') {
        changeMainMarkets();
      } else {
        // fetchMarkets({
        //   variables: {
        //     templateId,
        //     path: nodePath,
        //     lineType,
        //     markets_limit: marketsPaginationRef.current.markets_limit,
        //     markets_offset: marketsPaginationRef.current.markets_offset,
        //     search_query: searchValueRef.current,
        //   },
        // });
        fetchMarketsPage({ searchValue: searchValueRef.current });
      }
      const setDisplayOptions =
        key === 'allMarkets' ? setAllMarkets : setMainMarkets;

      setTabKey(key);
      setDragDropDisabled(false);
      if (!isEmpty(template) && !dragDropDisabled) {
        applyTemplateConfigs(key, setDisplayOptions);
      } else {
        setDisplayOptions(currentValue => sortBy(currentValue, ['priority']));
      }
    },
    [
      // templateId,
      changeMainMarkets,
      setAllMarkets,
      setMainMarkets,
      applyTemplateConfigs,
      template,
      dragDropDisabled,
      // fetchMarkets,
      fetchMarketsPage,
      // lineType,
      // nodePath,
    ]
  );

  const onChange = useCallback(
    e => {
      marketsPaginationRef.current.markets_offset = 0;
      searchValueRef.current = e.target ? e.target.value : e;

      if (searchValueRef.current.length === 1) {
        setDragDropDisabled(true);
      } else if (searchValueRef.current.length === 0) {
        setDragDropDisabled(false);
      }

      //in the All Markets tab we can use BE side search, because we can not add this items interactively
      //but in the Main Markets we can add items by select 'Main' group in specific market, than in this list we need to use client side search
      if (tabKey === 'allMarkets') {
        fetchMarketsPage({ searchValue: searchValueRef.current });
      } else {
        const filteredOptions = mainMarketsRef.current.filter(
          option =>
            option && option.name.toLowerCase().includes(searchValueRef.current)
        );

        applyTemplateConfigs(tabKey, setMainMarkets, filteredOptions);
      }
    },
    [fetchMarketsPage, applyTemplateConfigs, tabKey, marketsPaginationRef]
  );

  const saveReorderChanges = useCallback(
    ({ startIndex, endIndex, reorderedOptions }) => {
      const template = getValues('template');
      setIsNestedDragDropListsAvailable(true);

      reorderedOptions.forEach((value, index) => {
        //we need to add changes for values inside of range startIndex and endIndex
        //start index can be greater than final (but indexes cant be equal cause in this case nothing changed)
        if (
          (endIndex > startIndex
            ? index >= startIndex && index <= endIndex
            : index >= endIndex && index <= startIndex) ||
          tabKey === 'mainMarkets'
        ) {
          const nodeSelector = withoutEmpty({
            sportId: nodePath.sportId,
            categoryId: nodePath.tournamentId ? undefined : nodePath.categoryId,
            tournamentId: nodePath.tournamentId,
            eventId: nodePath.eventId,
            market: {
              marketId: value.marketId,
            },
          });

          const rootNodeSelector = {
            sportId: nodePath.sportId,
            market: { marketId: value.marketId },
          };

          const keySelector = getKeySelector({
            nodeSelector,
            lineType,
          });

          const rootKeySelector = getKeySelector({
            nodeSelector: rootNodeSelector,
            lineType,
          });

          const currentConfig = template[keySelector] || {};
          const rootConfig = template[rootKeySelector] || {};

          if (index === endIndex) {
            const groups =
              rootConfig.groups ||
              (value.marketGroupPriority &&
                value.marketGroupPriority.map(group => ({
                  selector: {
                    marketId: group.marketGroup.marketId,
                  },
                  group: group.marketGroup.name,
                  status: group.status,
                  priority: group.priority,
                })));

            // const cashOut = getParentCashOut({
            //   template,
            //   config: currentConfig,
            //   marketType,
            //   nodePath,
            //   lineType,
            //   itemValue: value,
            // });

            if (tabKey === 'allMarkets') {
              template[keySelector] = {
                lineType,
                nodeSelector,
                status: currentConfig.status ?? value.status,
                priority: endIndex + 1,
                // cashOut: cashOut.value,
                mainMarketPriority: rootConfig.mainMarketPriority,
                groups,
              };
            } else {
              template[rootKeySelector] = {
                lineType,
                nodeSelector: rootNodeSelector,
                priority: rootConfig.priority,
                // cashOut: rootConfig.cashOut ?? cashOut.value,
                status: rootConfig.status ?? value.status,
                mainMarketPriority: endIndex + 1,
                groups,
              };
            }
          }
        }
      });
      setValue('template', template);
    },
    [lineType, getValues, setValue, nodePath, tabKey]
  );

  useEffect(() => {
    if (!isEmpty(template) && !dragDropDisabled && !loading) {
      const setDisplayOptions =
        tabKey === 'allMarkets' ? setAllMarkets : setMainMarkets;

      applyTemplateConfigs(tabKey, setDisplayOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketType, options]);

  useEffect(() => {
    if (preset.commands.length > 0) {
      const template = getValues('template');

      const setDisplayOptions =
        tabKey === 'allMarkets' ? setAllMarkets : setMainMarkets;

      applyCoveragePresets({
        preset,
        template,
        setValue,
        lineType,
        switchedFrom,
        pathToSelect: {
          sportId: nodePath.sportId,
          categoryId:
            preset.idKey === 'tournamentId' ? undefined : nodePath.categoryId,
        },
      });
      setPreset(initialPreset);

      applyTemplateConfigs(tabKey, setDisplayOptions);
    }

    // eslint-disable-next-line
  }, [preset]);

  useEffect(() => {
    //we cant refresh value while we use search (when dragDropDisabled === true)
    if (!isEqual(mainMarketsRef.current, mainMarkets) && !dragDropDisabled) {
      mainMarketsRef.current = mainMarkets;
    }
  }, [mainMarkets, dragDropDisabled]);

  const ListComponent =
    tabKey === 'allMarkets' ? InfiniteMenu : VirtualizedMenu;

  return (
    <SelectContainer style={{ width: '450px' }}>
      <SelectSearch
        label={`Search ${capitalize(marketType)}'s Market`}
        onSearchFieldChange={onChange}
        tabKey={tabKey}
      />
      <MenuCaption>
        <MenuTitle name={'Market'} />
      </MenuCaption>
      <Tabs
        activeTabKey={'allMarkets'}
        fullWidthTabs
        primary
        tabs={tabs}
        onChangeTab={changeTabContent}
        tabsStyle={{
          marginBottom: '8px',
          width: 'calc(100% - 12px)',
        }}
      />
      <DraggableSelectMenu
        options={displayOptions}
        setDisplayOptions={
          tabKey === 'allMarkets' ? setAllMarkets : setMainMarkets
        }
        afterDrop={saveReorderChanges}
        onDragStart={() => setIsNestedDragDropListsAvailable(false)}
      >
        <ListComponent
          containerStyle={{ height: 'calc(100% - 175px)' }}
          isRowLoaded={({ index }) => !!displayOptions[index]}
          loadMoreRows={loadRows}
          rowCount={displayOptions.length}
          itemRender={({ measure, index }) => {
            const option = displayOptions[index];

            return (
              displayOptions[index] && (
                <Box position={'relative'}>
                  {(option.readOnly || loading) && (
                    <DisabledItemArea loading={loading} />
                  )}
                  <CoverageMarketOptionComponent
                    key={tabKey}
                    option={option}
                    selectedId={selectedId}
                    index={index}
                    selectKey={selectKey}
                    nodePath={nodePath}
                    nodeIndex={nodeIndex}
                    lineType={lineType}
                    isMenuAvailable={isNestedDragDropListsAvailable}
                    dragDropDisabled={dragDropDisabled}
                    configurationDisabled={!isCoverageWriter}
                    tabKey={tabKey}
                    marketType={marketType}
                    mainMarkets={mainMarkets}
                    setMainMarkets={setMainMarkets}
                    measure={measure}
                  />
                </Box>
              )
            );
          }}
        />

        {isPresetsAvailable && (
          <SavePresetButton
            templateType={'coverage'}
            onSave={onCoveragePresetSave}
            getPresetNamesQuery={getCoveragePresetNames}
            sportId={sportId}
          />
        )}
      </DraggableSelectMenu>
    </SelectContainer>
  );
};

export default CoverageMarketSelect;
