import { useQuery } from '@apollo/client';
import { Autocomplete, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ErrorWidget from '../../../../components/ErrorWidget';
import TextField from '../../../../components/Inputs/TextField';
import getLanguageInfo from '../../../../gql/EventsGroups/queries/getLanguageInfo';
import {
  useCompetitors,
  useLangsAPI,
} from '../../../../hooks/event-management';
import useDebounced from '../../../../hooks/useDebounced';
import {
  isCustomOption,
  mapDataToAutocompleteValue,
  mapLangsToTranslations,
  sortLanguages,
} from '../../utils';
import TranslationsList from '../TranslationsList';
import EventFormAutocompleteOption from '../EventFormAutocompleteOption';

const CompetitorField = ({
  competitor,
  index,
  sportId,
  languages,
  predefinedCompetitor,
}) => {
  const { setValue } = useFormContext();

  const fieldKey = `competitors.${index}`;

  const value = useMemo(
    () => mapDataToAutocompleteValue(competitor),
    [competitor]
  );

  const [inputValue, setInputValue] = useState(predefinedCompetitor || '');

  const {
    competitors,
    refetch: refetchCompetitors,
    error: competitorsError,
  } = useCompetitors({
    variables: {
      query: inputValue,
      sportIds: [sportId],
      limit: 15,
    },
  });

  const { getCompetitorLangs, error } = useLangsAPI();

  const { data: { getLanguageInfo: languagesData } = {} } =
    useQuery(getLanguageInfo);

  const [isExpandedLanguages, setExpandedLanguages] = useState(false);
  const debouncedCompetitorSearch = useDebounced(setInputValue, 500);

  const handleApplyCustomValue = async (event, competitor) => {
    const value = event.target.value;
    const primaryTranslation = competitor?.langs
      ? competitor.langs.find(l => l.language === 'English').translation
      : null;
    const existingOption = competitors.find(({ name }) => {
      const segments = name.split('/');
      const label = segments[segments.length - 1].trim();
      return label === value;
    });

    if (value) {
      if (value !== primaryTranslation) {
        if (existingOption) {
          const { data } = await getCompetitorLangs({
            sportId,
          });
          setValue(fieldKey, {
            ...data.getCompetitorLangs,
            langs: mapLangsToTranslations(
              sortLanguages(data.getCompetitorLangs.langs),
              languagesData
            ),
          });
        } else {
          setValue(fieldKey, {
            id: null,
            countryCode: '',
            langs: [
              {
                language: 'English',
                translation: value,
              },
            ],
          });
        }
      }
    } else {
      setValue(fieldKey, null);
    }
  };

  // const handleApplyCustomValue = () => {};

  useEffect(() => {
    if (inputValue) {
      refetchCompetitors({ query: inputValue, sportIds: [sportId] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  if (error || competitorsError) {
    return <ErrorWidget />;
  }

  return (
    <>
      <Stack gap={'4px'} direction={'row'}>
        <Stack gap={'2px'} sx={{ width: '100%' }}>
          <Autocomplete
            value={value}
            freeSolo
            filterOptions={options => options}
            getOptionLabel={option => {
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.label;
            }}
            onChange={async (event, newValue, reason) => {
              if (reason === 'selectOption') {
                const { data } = await getCompetitorLangs({
                  sportId,
                  competitorId: newValue?.value,
                });
                setValue(fieldKey, {
                  ...data.getCategoryLangs,
                  langs: mapLangsToTranslations(
                    sortLanguages(data.getCompetitorLangs.langs),
                    languagesData
                  ),
                  id: newValue?.value,
                });
              }
            }}
            onBlur={async event => {
              handleApplyCustomValue(event);
            }}
            options={competitors.map(c => ({ label: c.name, value: c.id }))}
            renderOption={(props, option) => (
              <EventFormAutocompleteOption option={option} {...props} />
            )}
            sx={{ width: '100%' }}
            renderInput={params => (
              <TextField
                shrinkable
                label={`Competitor ${index + 1}`}
                onKeyDown={async event => {
                  if (event.key === 'Enter' && event.target.value) {
                    handleApplyCustomValue(event);
                  }
                }}
                helperText={
                  isCustomOption(competitor)
                    ? 'New competitor will be added'
                    : value?.value
                }
                FormHelperTextProps={{
                  onClick: e => {
                    e.stopPropagation();
                  },
                  onMouseDown: e => {
                    e.stopPropagation();
                  },
                }}
                {...params}
              />
            )}
            onInputChange={(_, newInputValue) => {
              debouncedCompetitorSearch(newInputValue);
            }}
          />
        </Stack>
        <TextField
          disabled={!competitor}
          value={competitor ? competitor.countryCode : ''}
          onChange={e =>
            setValue(`${fieldKey}.countryCode`, e.target.value.toUpperCase())
          }
          sx={{ width: '108px' }}
        />
      </Stack>
      {competitor?.langs && (
        <TranslationsList
          name={fieldKey}
          langs={competitor.langs}
          languages={languages}
          isExpanded={isExpandedLanguages}
          setExpanded={setExpandedLanguages}
          onChange={langs => {
            setValue(fieldKey, {
              id: competitor?.id,
              countryCode: competitor?.countryCode,
              langs: langs,
            });
          }}
        />
      )}
    </>
  );
};

export default CompetitorField;
