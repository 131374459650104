import { gql } from '@apollo/client';

const cancelBetslips = gql`
  mutation cancelBetslips(
    $betslipsToCancel: [CancelBetslip!]
    $cancelReason: Int!
  ) {
    data: cancelBetslips(
      betslipsToCancel: $betslipsToCancel
      cancelReason: $cancelReason
    )
  }
`;

export default cancelBetslips;
